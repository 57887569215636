import { memo, useEffect } from "react";
import { createFileRoute, Navigate, Outlet } from "@tanstack/react-router";

import { AppSidebar } from "@/app-sidebar";
import Header from "@/nav/Header";
import { SidebarProvider } from "@/ui/sidebar";

import "../styles/layout.scss";

import { useAuth } from "~/hooks/useAuth";
import { AuthService } from "~/services/auth/authService";

const AuthLayout = () => {
  const { isAuthenticated } = useAuth();

  // Initialize auth on component mount
  useEffect(() => {
    AuthService.initializeAuth();
  }, []);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <SidebarProvider>
      <AppSidebar />
      <MainContent />
    </SidebarProvider>
  );
};

export const Route = createFileRoute("/_auth")({
  component: AuthLayout,
});

const MainContent = memo(() => (
  <main className="w-page-width grow overflow-hidden .group[data-collapsible='icon']:w-page-width-icon">
    <Header />
    <div className="overflow-auto w-[calc(100vw - var(--sidebar-width))] h-page-height">
      <Outlet />
    </div>
  </main>
));

MainContent.displayName = "MainContent";
