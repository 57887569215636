import { ReactNode, useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";

import "~/pages/Users/user.css";

import { DeleteOptions } from "~/api/model";
import { getGetUserByIdV1UsersUserIdGetQueryOptions, useDeleteUserV1UsersUserIdDelete } from "~/api/users/users.gen";
import { PersonalDataSection } from "~/components/users/PersonalDataSection";
import { StreakSection } from "~/components/users/StreakSection";
import { SuspiciousActivitySection } from "~/components/users/SuspiciousActivitySection";
import { MemoizedUserProfileForm } from "~/components/users/UserProfileForm";
import { UserTransactionsSection } from "~/components/users/UserTransactionsSection";
import { parseFastAPIError } from "~/helpers/parse-errors";
import Button from "~/oldComponents/button/Button";
import PageLayout from "~/oldComponents/layout/PageLayout";
import { useAuthStore } from "~/store/authStore";

export const Route = createFileRoute("/_auth/users/$userId")({
  component: () => <UserPage />,
});

function UserPage() {
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const userId = Route.useParams().userId;
  const loggedInUser = useAuthStore((state) => state.user);

  const {
    data: user,
    isFetching,
    refetch: userRefetch,
  } = useQuery({
    ...getGetUserByIdV1UsersUserIdGetQueryOptions(userId),
    retry: (failureCount, error) => {
      if (error?.response?.status === 404) return false;
      return failureCount < 3;
    },
    throwOnError: (error) => {
      return error?.response?.status !== 404;
    },
  });

  const refetch = useCallback(() => {
    userRefetch();
  }, [userRefetch]);

  const deleteUserMutation = useDeleteUserV1UsersUserIdDelete({
    mutation: {
      onMutate: () => {
        queryClient.invalidateQueries({ queryKey: ["user", userId] });
      },
      onSuccess: () => {
        toast("User was successfully deleted!");
        navigate({
          to: "/users",
          search: {
            page: 1,
            limit: 100,
          },
        });
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const onUserDelete = async (type: DeleteOptions) => {
    if (userId && userId !== loggedInUser?.user_id) {
      await deleteUserMutation.mutateAsync({ userId, params: { delete_type: type } });
    } else {
      toast.error("Error", { description: "You can not delete yourself." });
    }
  };

  const additionalActions = () => {
    return (
      <Button onClick={() => onUserDelete(DeleteOptions.Hard)} disabled={isFetching} styleType="danger">
        Hard delete
      </Button>
    );
  };

  return (
    <PageLayout
      loading={isFetching}
      title="Stepler user"
      description="Use this elements, if you want to show some hints or additional information"
      onDeleteClick={() => onUserDelete(DeleteOptions.Soft)}
      onDeleteText="Soft delete"
      additionalActions={additionalActions()}
    >
      <section className="flex flex-col gap-8">
        {user && (
          <div className="space-y-8">
            <PersonalDataSection
              _id={user._id}
              status={user.status}
              createdAt={user.createdAt}
              updatedAt={user.updatedAt}
              walletBalance={user.walletBalance}
              spentPoints={user.spentPoints}
              diamondShards={user.diamondShards}
              spentDiamondShards={user.spentDiamondShards}
              phonenumber={user.phonenumber}
              appleId={user.appleId}
              fbId={user.fbId}
              googleId={user.googleId}
              platform={user.platform}
              language={user.language}
              totalSteps={user.totalSteps}
              isPremium={user.isPremium}
            />
            {user && user.profile && (
              <MemoizedUserProfileForm
                firstName={user.profile.firstName ?? ""}
                lastName={user.profile.lastName ?? ""}
                birthdate={user.profile.birthdate ?? undefined}
                gender={user.profile.gender ?? undefined}
                refetch={refetch}
                userId={user._id as string}
                postalCode={user.profile.address?.postalCode ?? ""}
                city={user.profile.address?.city ?? ""}
                street={user.profile.address?.street ?? ""}
                country={user.profile.address?.country ?? ""}
                profileEmail={user.profile.email ?? ""}
                email={user.email ?? ""}
                comments={user.comments ?? ""}
              />
            )}
          </div>
        )}

        {user && <SuspiciousActivitySection user={user} refetch={refetch} />}

        {user && user.streak && <StreakSection userId={userId} streak={user.streak} />}

        {user && (
          <UserTransactionsSection userId={userId} points={user.walletBalance ?? 0} diamondShards={user.diamondShards ?? 0} refetchUser={refetch} />
        )}
      </section>
    </PageLayout>
  );
}
