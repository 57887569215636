import React, { ReactNode } from "react";
import { FormEventHandler, useEffect, useState } from "react";
import clsx from "clsx";

import Button from "../button/Button";

import { FormField } from "./FormField";
import { getTypedField } from "./helpers";
import { Field } from "./types";

import "./form.scss";

import { AutoRewardSubModelOutput } from "~/api/model";

type Props = {
  name: string;
  onSubmit: FormEventHandler;
  submitText: string;
  readonly?: boolean;
  vertical?: boolean;
  children?: ReactNode;
  templateFields: Field[];
  addedRow: () => void;
  deleteRow: (index: number) => void;
  initialData?: AutoRewardSubModelOutput[];
};

type FieldRow = {
  fields: Field[];
  data: AutoRewardSubModelOutput;
  index: number;
};

export const FormSet: React.FC<Props> = ({
  name,
  submitText,
  onSubmit,
  vertical,
  templateFields,
  addedRow,
  deleteRow,
  initialData,
  readonly,
  children,
}: Props) => {
  const [fieldRows, setFieldRows] = useState<FieldRow[]>([]);
  const classes = clsx({
    form: true,
    "form--vertical": vertical,
  });

  useEffect(() => {
    if (!initialData) return;
    const allRows: FieldRow[] = [];
    for (const [index, data] of initialData.entries()) {
      allRows.push({
        fields: templateFields,
        data,
        index,
      });
    }
    setFieldRows(allRows);
  }, [initialData, templateFields]);

  return (
    <form className={classes} onSubmit={onSubmit}>
      <div className="form__fields">
        {fieldRows.map((row, _index) => {
          return (
            <div className="form__row" key={_index}>
              {row.fields.map((field, index) => {
                if (readonly) {
                  return (
                    <div key={`${name}-${index}`}>
                      {field.label}: {row.data[field.name as keyof AutoRewardSubModelOutput] || "-"}
                    </div>
                  );
                }
                return (
                  <FormField key={`${name}-${index}`} name={field.name} label={field.label} helpText={field.helpText} big={field.big} row={field.row}>
                    {getTypedField(field, row.data[field.name as keyof AutoRewardSubModelOutput]?.toString(), row.index)}
                  </FormField>
                );
              })}
              {!readonly && <Button onClick={deleteRow.bind(deleteRow, row.index)} styleType="danger" icon="Close" />}
              <hr className="form__divider" />
            </div>
          );
        })}
      </div>
      {!readonly && (
        <div className="form__actions">
          {children}
          <Button onClick={addedRow} icon="Add" />
          <Button type="submit">{submitText}</Button>
        </div>
      )}
    </form>
  );
};
