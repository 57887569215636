/**
 * Generated by orval
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query';

import type {
  Error400Response,
  HTTPValidationError,
  PointLinkModelInput,
  PointLinkModelOutput,
  UpdatePointLinkInput
} from '.././model';

import { axiosInstance } from '.././base';
import type { ErrorType, BodyType } from '.././base';


type SecondParameter<T extends (...args: never) => unknown> = Parameters<T>[1];



/**
 * Get point links
 * @summary Get Point Links
 */
export const getPointLinksV1PointLinksGet = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<PointLinkModelOutput[]>(
      {url: `/v1/point_links/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetPointLinksV1PointLinksGetQueryKey = () => {
    return [`/v1/point_links/`] as const;
    }

    
export const getGetPointLinksV1PointLinksGetQueryOptions = <TData = Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPointLinksV1PointLinksGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>> = ({ signal }) => getPointLinksV1PointLinksGet(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetPointLinksV1PointLinksGetQueryResult = NonNullable<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>>
export type GetPointLinksV1PointLinksGetQueryError = ErrorType<unknown>


export function useGetPointLinksV1PointLinksGet<TData = Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>,
          TError,
          Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetPointLinksV1PointLinksGet<TData = Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>,
          TError,
          Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetPointLinksV1PointLinksGet<TData = Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Point Links
 */

export function useGetPointLinksV1PointLinksGet<TData = Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetPointLinksV1PointLinksGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetPointLinksV1PointLinksGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPointLinksV1PointLinksGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>> = ({ signal }) => getPointLinksV1PointLinksGet(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetPointLinksV1PointLinksGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>>
export type GetPointLinksV1PointLinksGetSuspenseQueryError = ErrorType<unknown>


export function useGetPointLinksV1PointLinksGetSuspense<TData = Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetPointLinksV1PointLinksGetSuspense<TData = Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetPointLinksV1PointLinksGetSuspense<TData = Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Point Links
 */

export function useGetPointLinksV1PointLinksGetSuspense<TData = Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPointLinksV1PointLinksGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetPointLinksV1PointLinksGetSuspenseQueryOptions(options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create point link
 * @summary Create Point Link
 */
export const createPointLinkV1PointLinksPost = (
    pointLinkModelInput: BodyType<PointLinkModelInput>,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<PointLinkModelOutput>(
      {url: `/v1/point_links/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: pointLinkModelInput, signal
    },
      options);
    }
  


export const getCreatePointLinkV1PointLinksPostMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPointLinkV1PointLinksPost>>, TError,{data: BodyType<PointLinkModelInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createPointLinkV1PointLinksPost>>, TError,{data: BodyType<PointLinkModelInput>}, TContext> => {
    
const mutationKey = ['createPointLinkV1PointLinksPost'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPointLinkV1PointLinksPost>>, {data: BodyType<PointLinkModelInput>}> = (props) => {
          const {data} = props ?? {};

          return  createPointLinkV1PointLinksPost(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreatePointLinkV1PointLinksPostMutationResult = NonNullable<Awaited<ReturnType<typeof createPointLinkV1PointLinksPost>>>
    export type CreatePointLinkV1PointLinksPostMutationBody = BodyType<PointLinkModelInput>
    export type CreatePointLinkV1PointLinksPostMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Point Link
 */
export const useCreatePointLinkV1PointLinksPost = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPointLinkV1PointLinksPost>>, TError,{data: BodyType<PointLinkModelInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createPointLinkV1PointLinksPost>>,
        TError,
        {data: BodyType<PointLinkModelInput>},
        TContext
      > => {

      const mutationOptions = getCreatePointLinkV1PointLinksPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get point link by id
 * @summary Get Point Link By Id
 */
export const getPointLinkByIdV1PointLinksPointLinkIdGet = (
    pointLinkId: string,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<PointLinkModelOutput>(
      {url: `/v1/point_links/${pointLinkId}/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetPointLinkByIdV1PointLinksPointLinkIdGetQueryKey = (pointLinkId: string,) => {
    return [`/v1/point_links/${pointLinkId}/`] as const;
    }

    
export const getGetPointLinkByIdV1PointLinksPointLinkIdGetQueryOptions = <TData = Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(pointLinkId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPointLinkByIdV1PointLinksPointLinkIdGetQueryKey(pointLinkId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>> = ({ signal }) => getPointLinkByIdV1PointLinksPointLinkIdGet(pointLinkId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(pointLinkId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetPointLinkByIdV1PointLinksPointLinkIdGetQueryResult = NonNullable<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>>
export type GetPointLinkByIdV1PointLinksPointLinkIdGetQueryError = ErrorType<Error400Response | HTTPValidationError>


export function useGetPointLinkByIdV1PointLinksPointLinkIdGet<TData = Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 pointLinkId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>,
          TError,
          Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetPointLinkByIdV1PointLinksPointLinkIdGet<TData = Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 pointLinkId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>,
          TError,
          Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetPointLinkByIdV1PointLinksPointLinkIdGet<TData = Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 pointLinkId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Point Link By Id
 */

export function useGetPointLinkByIdV1PointLinksPointLinkIdGet<TData = Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 pointLinkId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetPointLinkByIdV1PointLinksPointLinkIdGetQueryOptions(pointLinkId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetPointLinkByIdV1PointLinksPointLinkIdGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(pointLinkId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetPointLinkByIdV1PointLinksPointLinkIdGetQueryKey(pointLinkId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>> = ({ signal }) => getPointLinkByIdV1PointLinksPointLinkIdGet(pointLinkId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetPointLinkByIdV1PointLinksPointLinkIdGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>>
export type GetPointLinkByIdV1PointLinksPointLinkIdGetSuspenseQueryError = ErrorType<Error400Response | HTTPValidationError>


export function useGetPointLinkByIdV1PointLinksPointLinkIdGetSuspense<TData = Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 pointLinkId: string, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetPointLinkByIdV1PointLinksPointLinkIdGetSuspense<TData = Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 pointLinkId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetPointLinkByIdV1PointLinksPointLinkIdGetSuspense<TData = Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 pointLinkId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Point Link By Id
 */

export function useGetPointLinkByIdV1PointLinksPointLinkIdGetSuspense<TData = Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError = ErrorType<Error400Response | HTTPValidationError>>(
 pointLinkId: string, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getPointLinkByIdV1PointLinksPointLinkIdGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetPointLinkByIdV1PointLinksPointLinkIdGetSuspenseQueryOptions(pointLinkId,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Update point link
 * @summary Update Point Link
 */
export const updatePointLinkV1PointLinksPointLinkIdPatch = (
    pointLinkId: string,
    updatePointLinkInput: BodyType<UpdatePointLinkInput>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<PointLinkModelOutput>(
      {url: `/v1/point_links/${pointLinkId}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updatePointLinkInput
    },
      options);
    }
  


export const getUpdatePointLinkV1PointLinksPointLinkIdPatchMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePointLinkV1PointLinksPointLinkIdPatch>>, TError,{pointLinkId: string;data: BodyType<UpdatePointLinkInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updatePointLinkV1PointLinksPointLinkIdPatch>>, TError,{pointLinkId: string;data: BodyType<UpdatePointLinkInput>}, TContext> => {
    
const mutationKey = ['updatePointLinkV1PointLinksPointLinkIdPatch'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePointLinkV1PointLinksPointLinkIdPatch>>, {pointLinkId: string;data: BodyType<UpdatePointLinkInput>}> = (props) => {
          const {pointLinkId,data} = props ?? {};

          return  updatePointLinkV1PointLinksPointLinkIdPatch(pointLinkId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdatePointLinkV1PointLinksPointLinkIdPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updatePointLinkV1PointLinksPointLinkIdPatch>>>
    export type UpdatePointLinkV1PointLinksPointLinkIdPatchMutationBody = BodyType<UpdatePointLinkInput>
    export type UpdatePointLinkV1PointLinksPointLinkIdPatchMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Update Point Link
 */
export const useUpdatePointLinkV1PointLinksPointLinkIdPatch = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePointLinkV1PointLinksPointLinkIdPatch>>, TError,{pointLinkId: string;data: BodyType<UpdatePointLinkInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updatePointLinkV1PointLinksPointLinkIdPatch>>,
        TError,
        {pointLinkId: string;data: BodyType<UpdatePointLinkInput>},
        TContext
      > => {

      const mutationOptions = getUpdatePointLinkV1PointLinksPointLinkIdPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete point link
 * @summary Delete Point Link
 */
export const deletePointLinkV1PointLinksPointLinkIdDelete = (
    pointLinkId: string,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/v1/point_links/${pointLinkId}/`, method: 'DELETE'
    },
      options);
    }
  


export const getDeletePointLinkV1PointLinksPointLinkIdDeleteMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePointLinkV1PointLinksPointLinkIdDelete>>, TError,{pointLinkId: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deletePointLinkV1PointLinksPointLinkIdDelete>>, TError,{pointLinkId: string}, TContext> => {
    
const mutationKey = ['deletePointLinkV1PointLinksPointLinkIdDelete'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePointLinkV1PointLinksPointLinkIdDelete>>, {pointLinkId: string}> = (props) => {
          const {pointLinkId} = props ?? {};

          return  deletePointLinkV1PointLinksPointLinkIdDelete(pointLinkId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeletePointLinkV1PointLinksPointLinkIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deletePointLinkV1PointLinksPointLinkIdDelete>>>
    
    export type DeletePointLinkV1PointLinksPointLinkIdDeleteMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Delete Point Link
 */
export const useDeletePointLinkV1PointLinksPointLinkIdDelete = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePointLinkV1PointLinksPointLinkIdDelete>>, TError,{pointLinkId: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deletePointLinkV1PointLinksPointLinkIdDelete>>,
        TError,
        {pointLinkId: string},
        TContext
      > => {

      const mutationOptions = getDeletePointLinkV1PointLinksPointLinkIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    