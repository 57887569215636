// eslint-disable no-unused-vars
import { useEffect } from "react";
import { QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";

import { TanStackRouterDevtools } from "@/TanStackRouterDevtools";
import { Toaster } from "@/ui/sonner";

import { ThemeProvider } from "~/providers/theme";

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
}>()({
  component: () => <Root />,
});

function Root() {
  useEffect(() => {
    document.title = `Stepler Admin - ${import.meta.env.VITE_REACT_APP_NODE_ENV}`;
  }, []);

  return (
    <>
      <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
        <div className="h-screen w-screen bg-background">
          <Outlet />
          <Toaster />
        </div>
      </ThemeProvider>
      {/* <ReactQueryDevtools position="left" /> */}
      {/* <TanStackRouterDevtools /> */}
      {/* <TanStackRouterDevtools /> */}
    </>
  );
}
