import React, { memo } from "react";
import type { Cell, ColumnDef, Row, Table } from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";

import { Table as UITable, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/ui/table";

import { OfferWithCategoriesAndTags } from "~/providers/marketplace";

interface RewardsTableHeaderProps {
  table: Table<OfferWithCategoriesAndTags>;
}

const RewardsTableHeader = memo(({ table }: RewardsTableHeaderProps) => (
  <TableHeader>
    {table.getHeaderGroups().map((headerGroup) => (
      <TableRow key={headerGroup.id} className="">
        {headerGroup.headers.map((header) => (
          <TableHead key={header.id}>
            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
          </TableHead>
        ))}
      </TableRow>
    ))}
  </TableHeader>
));

RewardsTableHeader.displayName = "RewardsTableHeader";

interface TableCellProps {
  cell: Cell<OfferWithCategoriesAndTags, unknown>;
  selected: boolean;
}

const areCellsEqual = (
  prevProps: TableCellProps,
  nextProps: TableCellProps
) => {
  if (prevProps.selected !== nextProps.selected) return false;

  if (!prevProps?.cell || !nextProps?.cell) return false;

  const prevCell = prevProps.cell;
  const nextCell = nextProps.cell;

  if (!prevCell.column?.columnDef?.cell || !nextCell.column?.columnDef?.cell) return false;

  try {
    return (
      flexRender(prevCell.column.columnDef.cell, prevCell.getContext()) ===
      flexRender(nextCell.column.columnDef.cell, nextCell.getContext())
    );
  } catch (e) {
    console.error(e);
    return false;
  }
};

const MemoizedCell = memo(({ cell }: TableCellProps) => (
  <TableCell key={cell.id}>
    {flexRender(cell.column.columnDef.cell, cell.getContext())}
  </TableCell>
), areCellsEqual);

MemoizedCell.displayName = "MemoizedCell";

interface TableRowProps {
  row: Row<OfferWithCategoriesAndTags>;
  selected: boolean;
}

const areRowsEqual = (
  prevProps: TableRowProps,
  nextProps: TableRowProps,
) => {
  // If selection state changed, we should re-render
  if (prevProps.selected !== nextProps.selected) return false;

  if (!prevProps?.row || !nextProps?.row) return false;


  const prevRow = prevProps.row;
  const nextRow = nextProps.row;

  if (!prevRow.original || !nextRow.original) return false;

  return (
    prevRow.id === nextRow.id &&
    prevRow.getIsSelected() === nextRow.getIsSelected() &&
    JSON.stringify(prevRow.original) === JSON.stringify(nextRow.original)
  );
};

const MemoizedRow = memo(({ row, selected }: TableRowProps) => (
  <TableRow key={row.id} data-state={selected && "selected"}>
    {row.getVisibleCells().map((cell) => (
      <MemoizedCell key={cell.id} cell={cell} selected={selected} />
    ))}
  </TableRow>
), areRowsEqual);

MemoizedRow.displayName = "MemoizedRow";

const DEFAULT_VIRTUALIZATION_CONFIG = {
  estimateSize: 80,
  overscan: 20,
};

interface RewardsTableProps {
  table: Table<OfferWithCategoriesAndTags>;
  columns: ColumnDef<OfferWithCategoriesAndTags>[];
  isLoading: boolean;
}

export const RewardsTable = memo(({ table, columns, isLoading }: RewardsTableProps) => {
  const { rows } = table.getRowModel()
  const parentRef = React.useRef<HTMLDivElement>(null)

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => DEFAULT_VIRTUALIZATION_CONFIG.estimateSize,
    overscan: DEFAULT_VIRTUALIZATION_CONFIG.overscan,
  })

  const LoadingOverlay = () => (
    <>
      <div className="absolute inset-0 bg-background/50 backdrop-blur-[1px] z-40" />
      <div className="flex fixed inset-0 z-50 justify-center items-center pointer-events-none">
        <div className="w-10 h-10 rounded-full border-2 animate-spin border-primary border-t-transparent" />
      </div>
    </>
  );

  return (
    < div className="flex relative flex-col gap-4">
      {isLoading && <LoadingOverlay />}
      <div ref={parentRef}>
        <UITable>
          <RewardsTableHeader table={table} />
          <TableBody>
            <TableRow className="border-0">
              <TableCell className="p-3" />
            </TableRow>
            {rows?.length ? (
              virtualizer.getVirtualItems().map((virtualRow) => {
                const row = rows[virtualRow.index]
                return (
                  <MemoizedRow key={row.id} row={row} selected={row.getIsSelected()} />
                )
              })
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  {isLoading ? "Loading..." : "No results."}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </UITable>
      </div>
    </div >
  )
});

RewardsTable.displayName = "RewardsTable";
