import React, { memo, useCallback } from "react";
import { useNavigate, UseNavigateResult } from "@tanstack/react-router";

import MultiSelectFormField from "@/multi_select";
import { Button } from "@/ui/button";
import { Input } from "@/ui/input";
import { Select, SelectContent, SelectItem, SelectSeparator, SelectTrigger, SelectValue } from "@/ui/select";

import { RewardSearch } from "./Rewards";

import { OfferFilterStatus, OfferType } from "~/api/model";
import { getCategoryName } from "~/helpers/category";
import { useMarketplace } from "~/providers/marketplace";

interface RewardsFilterProps {
  searchText?: string;
  status?: string;
  rewardType?: string;
  platform?: string;
  categories?: string[];
  tags?: string[];
  onStatusChange?: (value: string) => void;
}

export const RewardsFilter = memo(({
  searchText: initialSearchText,
  status,
  rewardType,
  platform,
  categories: selectedCategories,
  tags: selectedTags,
  onStatusChange
}: RewardsFilterProps) => {
  const { categories, tags } = useMarketplace();
  const navigate: UseNavigateResult<"/$market/marketplace/"> = useNavigate();

  const [searchText, setSearchText] = React.useState(initialSearchText ?? "");

  const handleSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  }, []);

  const handleSearchSubmit = useCallback((e?: React.MouseEvent | React.KeyboardEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, search: searchText }) });
  }, [navigate, searchText]);

  const handleSearchKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearchSubmit(event);
    }
  }, [handleSearchSubmit]);

  const handleStatusChange = useCallback((value: string) => {
    navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, status: value as OfferFilterStatus }) });
    if (onStatusChange) onStatusChange(value);
  }, [navigate, onStatusChange]);

  const handleRewardTypeChange = useCallback((value: string) => {
    navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, rewardType: value }) });
  }, [navigate]);

  const handleRewardTypeClear = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, rewardType: undefined }) });
  }, [navigate]);

  const handlePlatformChange = useCallback((value: string) => {
    navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, platform: value }) });
  }, [navigate]);

  const handlePlatformClear = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, platform: undefined }) });
  }, [navigate]);

  const handleCategoriesChange = useCallback((value: string[]) => {
    navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, categories: value }) });
  }, [navigate]);

  const handleTagsChange = useCallback((value: string[]) => {
    navigate({ replace: true, search: (prev: RewardSearch) => ({ ...prev, tags: value }) });
  }, [navigate]);

  return (
    <form className="flex flex-col gap-10 p-4 w-full rounded-lg bg-accent">
      <div className="flex gap-2">
        <Input
          placeholder="Search"
          value={searchText}
          onChange={handleSearchChange}
          onKeyDown={handleSearchKeyDown}
        />
        <Button onClick={handleSearchSubmit}>
          Search
        </Button>
      </div>
      <div className="grid grid-cols-5 gap-10 items-center w-full">
        <Select
          onValueChange={handleStatusChange}
          defaultValue={status ?? OfferFilterStatus.Active}
        >
          <SelectTrigger>
            <SelectValue placeholder="Status" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={OfferFilterStatus.Active}>Active</SelectItem>
            <SelectItem value={OfferFilterStatus.Published}>Published</SelectItem>
            <SelectItem value={OfferFilterStatus.Unpublished}>Unpublished</SelectItem>
            <SelectItem value={OfferFilterStatus.Archived}>Archived</SelectItem>
          </SelectContent>
        </Select>
        <Select
          onValueChange={handleRewardTypeChange}
          value={rewardType ?? OfferType.Regular}
        >
          <SelectTrigger>
            <SelectValue placeholder="Reward type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={OfferType.Regular}>Points only</SelectItem>
            <SelectItem value={OfferType.Diamond}>Has Diamonds</SelectItem>
            <SelectItem value={OfferType.Bonus}>Bonus Rewards only</SelectItem>
            <SelectItem value={OfferType.NewUserSpecialOffer}>New user special Rewards only</SelectItem>
            <SelectSeparator />
            <Button
              className="px-2 w-full"
              variant="secondary"
              size="sm"
              onClick={handleRewardTypeClear}
            >
              Clear
            </Button>
          </SelectContent>
        </Select>
        <Select
          onValueChange={handlePlatformChange}
          value={platform ?? ""}
        >
          <SelectTrigger>
            <SelectValue placeholder="Platform" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="ios">iOS only</SelectItem>
            <SelectItem value="android">Android only</SelectItem>
            <SelectSeparator />
            <Button
              className="px-2 w-full"
              variant="secondary"
              size="sm"
              onClick={handlePlatformClear}
            >
              Clear
            </Button>
          </SelectContent>
        </Select>
        <MultiSelectFormField
          singleLine
          placeholder="Categories"
          options={categories.map((category) => ({ label: getCategoryName(category.category) || "", value: category.category }))}
          defaultValue={selectedCategories}
          onValueChange={handleCategoriesChange}
        />
        <MultiSelectFormField
          singleLine
          placeholder="Tags"
          options={tags.map((tag) => ({ label: tag.name, value: tag._id as string }))}
          defaultValue={selectedTags}
          onValueChange={handleTagsChange}
        />
      </div>
    </form>
  );
});

RewardsFilter.displayName = "RewardsFilter";
