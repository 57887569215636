// eslint-disable-next-line simple-import-sort/imports
import React, { useCallback } from "react";
import { DragEndEvent } from "@dnd-kit/core";
import { ColumnDef, getCoreRowModel, getFilteredRowModel, useReactTable } from "@tanstack/react-table";
import { AxiosError } from "axios";
import { addMonths, subMinutes } from "date-fns";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { toast } from "sonner";

import { DnDDataTable, RowDragHandleCell } from "@/dnd_datatable";
import { Icon } from "@/icon/icon";
import { Button } from "@/ui/button";
import { Checkbox } from "@/ui/checkbox";
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/ui/dialog";
import { Input } from "@/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/ui/select";

import { BusinessRuleType, HighlightedCollectionType, OfferStatus, TagModel } from "~/api/model";
import { useUpdateBonusOfferV1OffersBonusOfferOfferIdPut, useUpdateOfferV1OffersOfferIdPut } from "~/api/offers/offers.gen";
import { cn } from "~/lib/utils";
import formatDate from "~/helpers/date-formatting";
import { prepareBonusOfferUpdate, prepareOfferUpdate } from "~/helpers/offer";
import { parseFastAPIError } from "~/helpers/parse-errors";
import {
  OfferWithCategoriesAndTags,
  useMarketplace,
} from "~/providers/marketplace";
import {
  useUpdateCategoryV1MarketplaceCategoriesCategoryIdPut,
  useUpdateTagV1MarketplaceTagsTagIdPut,
  useUpdateBusinessRuleRewardOrderV1MarketplaceBusinessRulesRuleIdRewardOrderPut,
} from "~/api/marketplace/marketplace.gen";
import { ConfirmDialog } from "@/confirm_dialog";
import { format } from "date-fns";
import { DatePicker } from "@/datepicker";
import { Label } from "@/ui/label";
import { Combobox, ComboboxOption } from "@/combobox";
import { getCategoryIcon } from "~/helpers/category";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isNumber(value: any) {
  if (value === "") {
    return false;
  }
  if (typeof value === "number" && !isNaN(value)) {
    return true;
  }
  if (typeof value === "string" && !isNaN(Number(value))) {
    return true;
  }
  return false;
}

export default function RewardOrderPopup({
  parentId,
  parentName,
  parentType,
  typeOfParent,
  rewardOrder,
  amountOfRewards,
  startDateFilter,
  endDateFilter,
  refetch,
  openDialog,
  setOpenDialog,
  showDelete,
}: {
  parentId: string;
  parentName: string;
  parentType?: BusinessRuleType;
  typeOfParent: HighlightedCollectionType;
  rewardOrder: string[];
  amountOfRewards?: number;
  startDateFilter?: string;
  endDateFilter?: string;
  refetch: () => void;
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  showDelete: boolean;
}) {
  const { activeRewards, newUserSpecialRewards, refetchMarketplace } = useMarketplace();

  const [localState, setLocalState] = useState<string[]>();
  const [rowSelection, setRowSelection] = useState({});
  const [name, setName] = useState<string>(parentName || "");
  const [localAmountOfRewards, setAmountOfRewards] = useState<number | undefined>(amountOfRewards);
  const [localStartDateFilter, setStartDateFilter] = useState<Date | undefined>(startDateFilter ? new Date(startDateFilter) : undefined);
  const [localEndDateFilter, setEndDateFilter] = useState<Date | undefined>(endDateFilter ? new Date(endDateFilter) : undefined);

  const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false);
  const [changeStatusDialogText, setChangeStatusDialogText] = useState("");
  const [changeStatusOffer, setChangeStatusOffer] = useState<OfferWithCategoriesAndTags | null>(null);
  const [changeStatusStatus, setChangeStatusStatus] = useState<OfferStatus | null>(null);

  const [columnVisibility, setColumnVisibility] = useState({
    "drag-handle":
      typeOfParent !== HighlightedCollectionType.BusinessRules ||
      (parentType && parentType === BusinessRuleType.NewUserSpecial) ||
      false,
    select: typeOfParent !== HighlightedCollectionType.BusinessRules,
    rewardType: false,
    platform: false,
    delete:
      typeOfParent !== HighlightedCollectionType.BusinessRules ||
      (parentType && parentType === BusinessRuleType.NewUserSpecial) ||
      false,
  });

  const rewardsMap = useMemo(() => new Map(
    activeRewards.map(reward => [reward.id, reward])
  ), [activeRewards]);

  useEffect(() => {
    setColumnVisibility({
      "drag-handle":
        typeOfParent !== HighlightedCollectionType.BusinessRules ||
        (parentType && parentType === BusinessRuleType.NewUserSpecial) ||
        false,
      select: typeOfParent !== HighlightedCollectionType.BusinessRules,
      rewardType: false,
      platform: false,
      delete:
        typeOfParent !== HighlightedCollectionType.BusinessRules ||
        (parentType && parentType === BusinessRuleType.NewUserSpecial) ||
        false,
    });
  }, [parentType, typeOfParent]);

  const categoryRewardUpdateMutation = useUpdateCategoryV1MarketplaceCategoriesCategoryIdPut({
    mutation: {
      onSuccess: () => {
        toast("Reward updated successfully");
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const tagRewardUpdateMutation = useUpdateTagV1MarketplaceTagsTagIdPut({
    mutation: {
      onSuccess: () => {
        toast("Reward updated successfully");
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const businessRuleRewardOrderUpdateMutation = useUpdateBusinessRuleRewardOrderV1MarketplaceBusinessRulesRuleIdRewardOrderPut({
    mutation: {
      onSuccess: () => {
        toast("Reward updated successfully");
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
    },
  });

  const updateOfferMutation = useUpdateOfferV1OffersOfferIdPut({
    mutation: {
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
      onSuccess: () => {
        toast("Reward updated successfully!");
        refetchMarketplace();
      },
    },
  });

  const updateBonusOfferMutation = useUpdateBonusOfferV1OffersBonusOfferOfferIdPut({
    mutation: {
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) as ReactNode });
      },
      onSuccess: () => {
        toast("Bonus Reward updated successfully!");
        refetchMarketplace();
      },
    },
  });

  useEffect(() => {
    setLocalState(rewardOrder);
  }, [rewardOrder, openDialog]);

  useEffect(() => {
    if (!openDialog) {
      setRowSelection({});
    }
  }, [openDialog]);

  function prepareStatusUpdate(offer: OfferWithCategoriesAndTags, status: OfferStatus) {
    if (offer.status === status) return;

    let statusText = `Are you sure you want to change the status of the reward "${offer.titleV2}" from <br/>"${offer.status}" to "${status}"?`;

    const now = new Date();
    let unpublishDate = new Date(offer.unPublishDate || now);
    if (offer.status === OfferStatus.Unpublished && status === OfferStatus.Published) {
      if (unpublishDate < now) {
        unpublishDate = addMonths(now, 3);
      }
      statusText += `|We will also set the publishDate to "${formatDate(now)}" and we will set the unpublishDate to "${formatDate(unpublishDate)}".`;
    }

    if (offer.status === OfferStatus.Published && status === OfferStatus.Unpublished) {
      if (unpublishDate > now) {
        unpublishDate = subMinutes(now, 5);
      }
      statusText += `|We will also set the unpublishDate to "${formatDate(unpublishDate)}".`;
    }

    if (status === OfferStatus.Archived) {
      statusText += `|This will remove the reward from the marketplace. This will set the publish and unpublish date to "${formatDate(now)}".`;
    }

    setChangeStatusDialogText(statusText);
    setChangeStatusOffer(offer);
    setChangeStatusStatus(status);
    setShowChangeStatusDialog(true);
  }

  function updateStatus() {
    if (!changeStatusOffer || !changeStatusStatus) return null;
    // TODO: Update the table to reflect the new status without needing to reload the data from the backend
    //
    const additionalUpdateFields: { publishDate?: string; unPublishDate?: string } = {};
    const now = new Date();
    const nowString = now.toISOString();
    const oneMonthLater = addMonths(now, 3).toISOString();
    if (changeStatusOffer.status === OfferStatus.Unpublished && changeStatusStatus === OfferStatus.Published) {
      additionalUpdateFields["publishDate"] = nowString;
      if (!changeStatusOffer.unPublishDate || changeStatusOffer.unPublishDate < nowString) {
        additionalUpdateFields["unPublishDate"] = oneMonthLater;
      }
    }

    if (changeStatusOffer.status === OfferStatus.Published && changeStatusStatus === OfferStatus.Unpublished) {
      const unpublishDate = subMinutes(now, 5);
      additionalUpdateFields["unPublishDate"] = unpublishDate.toISOString();
    }

    if (changeStatusStatus === OfferStatus.Archived) {
      additionalUpdateFields["publishDate"] = nowString;
      additionalUpdateFields["unPublishDate"] = nowString;
    }

    if (changeStatusOffer.isBonus) {
      return updateBonusOfferMutation.mutateAsync({
        offerId: changeStatusOffer._id as string,
        data: {
          ...prepareBonusOfferUpdate(changeStatusOffer),
          status: changeStatusStatus,
          ...additionalUpdateFields,
        },
      });
    } else {
      return updateOfferMutation.mutateAsync({
        offerId: changeStatusOffer._id as string,
        data: { ...prepareOfferUpdate(changeStatusOffer), status: changeStatusStatus, ...additionalUpdateFields },
      });
    }
  }

  function addToList(id: string) {
    rewardTable.getColumn("title")?.setFilterValue("");
    if (localState) {
      setLocalState([...localState, id]);
    } else {
      setLocalState([id]);
    }
  }

  // Memoize the delete handler to prevent recreating it on every render
  const handleDeleteReward = useCallback((rowId: string) => {
    setLocalState((data) => data ? data.filter((reward) => reward !== rowId) : []);
  }, []);

  const rewardColumns = useMemo<ColumnDef<OfferWithCategoriesAndTags>[]>(() => {
    function updateHideInList(offer: OfferWithCategoriesAndTags, hideInList: boolean) {
      if (!offer._id) return;

      if (offer.isBonus) {
        updateBonusOfferMutation.mutateAsync({
          offerId: offer._id,
          data: {
            ...prepareBonusOfferUpdate(offer),
            hideInList,
          },
        });
      } else {
        updateOfferMutation.mutateAsync({
          offerId: offer._id,
          data: {
            ...prepareOfferUpdate(offer),
            hideInList,
          },
        });
      }
    }
    return [
      {
        id: "drag-handle",
        header: "",
        cell: ({ row }) => {
          if (
            typeOfParent === HighlightedCollectionType.BusinessRules &&
            parentType &&
            parentType !== BusinessRuleType.NewUserSpecial
          ) {
            return null;
          }
          return <RowDragHandleCell rowId={row.id} />;
        },
      },
      {
        id: "select",
        header: ({ table }) => {
          if (typeOfParent === HighlightedCollectionType.BusinessRules) {
            return null;
          }
          return (
            <Checkbox
              checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")}
              onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
              aria-label="Select all"
            />
          );
        },
        cell: ({ row }) => {
          if (typeOfParent === HighlightedCollectionType.BusinessRules) {
            return null;
          }
          if (typeOfParent === HighlightedCollectionType.Category && row.original.categories.length <= 1) {
            return (
              <Checkbox
                title="Reward only has one category. So we can not remove it from this category"
                checked={row.getIsSelected()}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                aria-label="Select row"
                disabled
              />
            );
          }
          return <Checkbox checked={row.getIsSelected()} onCheckedChange={(value) => row.toggleSelected(!!value)} aria-label="Select row" />;
        },
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: "logoUrl",
        header: "",
        cell: ({ row }) => {
          const logo: string = row.getValue("logoUrl");
          if (logo) {
            return <img src={`${import.meta.env.VITE_ASSETS_DOMAIN}/${logo}`} className="w-10 h-10 rounded-full min-h-10 min-w-10" />;
          }
        },
      },
      {
        accessorKey: "title",
        header: "Reward",
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => {
          return (
            <Select onValueChange={(value) => prepareStatusUpdate(row.original, value as OfferStatus)} defaultValue={row.original.status}>
              <SelectTrigger
                className={cn(
                  "justify-center gap-1 rounded-full bg-archived p-2 text-archived-foreground",
                  row.original.status === "Published" && "bg-published text-published-foreground",
                  row.original.status === "Unpublished" && "bg-unpublished text-unpublished-foreground",
                )}
              >
                <SelectValue placeholder="Select a status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={OfferStatus.Published}>Published</SelectItem>
                <SelectItem value={OfferStatus.Unpublished}>Unpublished</SelectItem>
                <SelectItem value={OfferStatus.Archived}>Archived</SelectItem>
              </SelectContent>
            </Select>
          );
        },
      },
      {
        accessorKey: "hideInList",
        header: "Hidden",
        cell: ({ row }) => {
          return (
            <Checkbox checked={row.original.hideInList} onCheckedChange={(checked) => updateHideInList(row.original, checked as boolean)} />
          );
        },
      },
      {
        id: "price",
        header: ({ column }) => {
          return (
            <Button variant="ghost" className="px-0" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              Currency
            </Button>
          );
        },
        cell: ({ row }) => {
          const hasShards = !!row.original.diamonds && row.original.diamonds !== 0;
          const hasAmount = !!row.original.price && row.original.price !== 0;

          if (!hasShards && !hasAmount) return "-";

          return (
            <div className="flex flex-col gap-2">
              {hasShards && (
                <div className="flex gap-2 items-center">
                  <Icon className="fill-[#6fb8f1] text-[#479bdf]" icon="Diamond" /> {row.original.diamonds}
                </div>
              )}
              {hasAmount && (
                <div className="flex gap-2 items-center">
                  <Icon icon="Coin" /> {row.original.price}
                </div>
              )}
            </div>
          );
        },
      },
      {
        id: "available",
        header: () => {
          return (
            <div>
              Total Inventory
              <br />
              <small>Available/Claimed</small>
            </div>
          );
        },
        cell: ({ row }) => {
          return (
            <div>
              {row.original.amount}
              <br />
              <small>
                {row.original.amount - (row.original.verified || 0)}/{row.original.verified}
              </small>
            </div>
          );
        },
      },
      {
        id: "codes",
        header: () => {
          return (
            <div>
              Codes
              <br />
              <small>Unused/Used</small>
            </div>
          );
        },
        cell: ({ row }) => (
          <div>
            {row.original.unused_coupon_count}/{(row.original.coupon_count || 0) - (row.original.unused_coupon_count || 0)}
          </div>
        ),
      },
      {
        accessorKey: "categories",
        header: () => "Categories",
        cell: ({ row }) => {
          const categories = row.original.categories;
          return (
            <div className="flex gap-4 items-center">
              {categories.map((category) => (
                <div key={`${category._id}-${row.id}`} className="p-2 rounded-md bg-accent text-accent-foreground">
                  <Icon className="w-5 h-5" icon={getCategoryIcon(category.category)} />
                </div>
              ))}
            </div>
          );
        },
      },
      {
        accessorKey: "tags",
        header: () => "Tags",
        cell: ({ row }) => {
          const tags = row.original.tags;
          return tags.map((tag: TagModel) => <div key={tag._id}>{tag.name}</div>);
        },
      },
      {
        accessorKey: "schedule",
        header: () => "Schedule",
        cell: ({ row }) => {
          return (
            <div className="flex flex-col gap-2">
              <div className="whitespace-nowrap">Start: {formatDate(row.original.publishDate || "") as ReactNode}</div>
              <div className="whitespace-nowrap">End: {formatDate(row.original.unPublishDate || "") as ReactNode}</div>
            </div>
          );
        },
      },
      {
        id: "delete",
        cell: ({ row }) => {
          if (
            (typeOfParent === HighlightedCollectionType.Category && row.original.categories.length <= 1) ||
            (typeOfParent === HighlightedCollectionType.BusinessRules && parentType && parentType !== BusinessRuleType.NewUserSpecial)
          ) {
            return null;
          }
          return (
            <Button
              variant="ghost"
              onClick={() => handleDeleteReward(row.id)}
            >
              <Icon icon="Close" />
            </Button>
          );
        },
      },
    ];
  }, [typeOfParent, parentType, updateBonusOfferMutation, updateOfferMutation, handleDeleteReward]);

  const rewards = useMemo(() => {
    if (!localState || localState.length === 0) return [];
    // Create a new array only when localState or rewardsMap changes
    const result = [];
    for (const id of localState) {
      const reward = rewardsMap.get(id);
      if (reward) result.push(reward);
    }
    return result;
  }, [localState, rewardsMap])

  const rewardTable = useReactTable({
    data: rewards,
    columns: rewardColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (row) => row.id as string,
    enableRowSelection: (row) => typeOfParent === HighlightedCollectionType.Category && row.original.categories.length > 1,
    onRowSelectionChange: setRowSelection,
    state: {
      columnVisibility,
      rowSelection,
    },
  });

  const callbackHandleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;

    if (!active || !over || active.id === over.id) return;

    // Get the current state
    setLocalState((currentIds) => {
      if (!currentIds || currentIds.length === 0) return currentIds;

      // Find the indexes of the items being dragged
      const oldIndex = currentIds.findIndex(id => id === active.id);
      const newIndex = currentIds.findIndex(id => id === over.id);

      // Validate indexes
      if (oldIndex === -1 || newIndex === -1) {
        console.warn('Invalid indexes:', { oldIndex, newIndex });
        return currentIds;
      }

      // Create a new array with the items reordered
      const newOrder = [...currentIds];
      const [removed] = newOrder.splice(oldIndex, 1);
      newOrder.splice(newIndex, 0, removed);

      return newOrder;
    });
  }, []);

  function saveRewardChanges() {
    if (typeOfParent === HighlightedCollectionType.Category) {
      categoryRewardUpdateMutation.mutate({
        categoryId: parentId as string,
        data: {
          rewardOrder: rewards.map((reward) => reward.id as string),
        },
      });
    } else if (typeOfParent === HighlightedCollectionType.Tag) {
      tagRewardUpdateMutation.mutate({
        tagId: parentId as string,
        data: {
          name,
          rewardOrder: rewards.map((reward) => reward.id as string),
        },
      });
    } else if (typeOfParent === HighlightedCollectionType.BusinessRules) {
      businessRuleRewardOrderUpdateMutation.mutate({
        ruleId: parentId as string,
        data: {
          name,
          rewardOrder: rewards.map((reward) => reward.id as string),
          amountOfRewards: isNumber(localAmountOfRewards) ? localAmountOfRewards : undefined,
          startDateFilter: localStartDateFilter ? format(localStartDateFilter, "yyyy-MM-dd") : undefined,
          endDateFilter: localEndDateFilter ? format(localEndDateFilter, "yyyy-MM-dd") : undefined,
        },
      });
    } else {
      toast.info("Parent Type is unknow. So can not update");
    }
    setTimeout(() => {
      refetch();
      setOpenDialog(false);
    }, 500);
  }

  function deleteSelectedRewards() {
    const selectedRows = rewardTable.getSelectedRowModel().rows;

    const selectedIds: string[] = selectedRows.map((row) => row.original.id as string);
    setLocalState((data) => data?.filter((reward) => !selectedIds.includes(reward)));
    setRowSelection([]);
  }

  useEffect(() => {
    rewardTable.getColumn("title")?.setFilterValue("");
    if (!openDialog) {
      setLocalState(undefined);
    }
  }, [openDialog, rewardTable]);

  const additionalData =
    typeOfParent !== HighlightedCollectionType.BusinessRules || (parentType && parentType !== BusinessRuleType.NewUserSpecial)
      ? activeRewards
      : newUserSpecialRewards;

  return (
    <div>
      <Dialog open={openDialog} onOpenChange={setOpenDialog}>
        <DialogContent className="w-10/12 max-w-10/12" hideClose>
          <DialogHeader>
            <DialogTitle className="flex justify-between items-center text-bold">
              {typeOfParent === HighlightedCollectionType.Category ? (
                name
              ) : (
                <div className="flex gap-1 items-center">
                  <Input value={name} onChange={(event) => setName(event.currentTarget.value)} className="[field-sizing:content]" />
                  <Icon icon="Edit" />
                </div>
              )}
              <div className="flex gap-10 items-center">
                <Input
                  placeholder="Search"
                  value={(rewardTable.getColumn("title")?.getFilterValue() as string) || ""}
                  onChange={(event) => {
                    rewardTable.getColumn("title")?.setFilterValue(event.target.value);
                  }}
                />
                <DialogClose asChild>
                  <Button variant="ghost">
                    <Icon icon="Close" />
                  </Button>
                </DialogClose>
              </div>
            </DialogTitle>
          </DialogHeader>
          <div className="flex flex-row-reverse justify-between items-end">
            <div className="w-1/3">
              {(typeOfParent !== HighlightedCollectionType.BusinessRules || (parentType && parentType === BusinessRuleType.NewUserSpecial)) && (
                <Combobox
                  key={typeOfParent}
                  searchName="Search"
                  placeholder="Search to add"
                  options={additionalData
                    .filter((data) => !rewardTable.options.data.find((offer) => offer._id?.toString() === data._id?.toString()))
                    .map((offer) => ({ label: `${offer.titleV2} - ${offer.subTitle}`, value: offer.id }))}
                  onChange={(value: ComboboxOption) => {
                    addToList(value.value as string);
                  }}
                />
              )}
            </div>
            {typeOfParent === HighlightedCollectionType.BusinessRules && parentType && parentType !== BusinessRuleType.All && (
              <div className="w-1/3">
                {parentType && parentType === BusinessRuleType.MostSold && (
                  <div className="flex items-center space-x-2">
                    <Label htmlFor="terms">Amount of rewards</Label>
                    <Input
                      type="number"
                      value={localAmountOfRewards}
                      onChange={(event) => {
                        if (isNumber(event.currentTarget.value)) {
                          setAmountOfRewards(+event.currentTarget.value);
                        } else {
                          setAmountOfRewards(undefined);
                        }
                      }}
                      className="[field-sizing:content]"
                    />
                  </div>
                )}
                {parentType && parentType === BusinessRuleType.NewUserSpecial && (
                  <>
                    <div className="flex items-center space-x-2">
                      <Label htmlFor="terms">Start date filter</Label>
                      <DatePicker
                        key={localStartDateFilter?.toUTCString()}
                        defaultValue={localStartDateFilter}
                        onChange={(date: Date | undefined) => setStartDateFilter(date)}
                      />
                    </div>
                    <div className="flex items-center space-x-2">
                      <Label htmlFor="terms">End date filter</Label>
                      <DatePicker
                        key={localEndDateFilter?.toUTCString()}
                        defaultValue={localEndDateFilter}
                        onChange={(date: Date | undefined) => setEndDateFilter(date)}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="overflow-x-auto">
            <div className="max-h-[60dvh] overflow-y-auto">
              {/* Force full destruction and rerendering of DnDDataTable when localState changes */}
              {localState && (
                <DnDDataTable
                  key={`dnd-table-${Date.now()}-${localState.join(',')}`}
                  table={rewardTable}
                  columns={rewardColumns}
                  handleDragEnd={callbackHandleDragEnd}
                  dataIds={localState}
                />
              )}
            </div>
          </div>
          <DialogFooter>
            {Object.keys(rowSelection).length > 0 && (
              <Button variant="destructive" onClick={deleteSelectedRewards}>
                Delete Rewards from {parentName}
              </Button>
            )}
            {showDelete && (
              <Button onClick={() => null} variant="destructive">
                Delete
              </Button>
            )}
            <DialogClose asChild>
              <Button variant="outline">Cancel</Button>
            </DialogClose>
            <Button onClick={saveRewardChanges}>Save</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        openDialog={showChangeStatusDialog}
        setOpenDialog={setShowChangeStatusDialog}
        title={changeStatusDialogText}
        confirmAction={updateStatus}
        confirmText={`set to ${changeStatusStatus}`}
      />
    </div>
  );
}
