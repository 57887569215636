/**
 * Generated by orval
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery,
  useSuspenseQuery
} from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult
} from '@tanstack/react-query';

import type {
  BulkAddTagsToOffersInput,
  BulkChangeOffersStatusInput,
  BusinessRulesCollectionModel,
  CategoryMarketModel,
  CategoryRewardOrder,
  CreateBusinessRuleInput,
  CreateTagInput,
  CreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostParams,
  CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostBodyItem,
  CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostParams,
  CreatedUpdatedDeleted,
  Error400Response,
  GetMarketplaceDataV1MarketplaceGetParams,
  HTTPValidationError,
  HighlightedCollectionModelInput,
  MarketplaceResponseModel,
  RewardOrderUpdate,
  TagModel
} from '.././model';

import { axiosInstance } from '.././base';
import type { ErrorType, BodyType } from '.././base';


type SecondParameter<T extends (...args: never) => unknown> = Parameters<T>[1];



/**
 * Get all marketplace data (offers, categories, tags, highlighted collections and recommended
 * @summary Get Marketplace Data
 */
export const getMarketplaceDataV1MarketplaceGet = (
    params: GetMarketplaceDataV1MarketplaceGetParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<MarketplaceResponseModel>(
      {url: `/v1/marketplace/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetMarketplaceDataV1MarketplaceGetQueryKey = (params: GetMarketplaceDataV1MarketplaceGetParams,) => {
    return [`/v1/marketplace/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetMarketplaceDataV1MarketplaceGetQueryOptions = <TData = Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError = ErrorType<HTTPValidationError>>(params: GetMarketplaceDataV1MarketplaceGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMarketplaceDataV1MarketplaceGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>> = ({ signal }) => getMarketplaceDataV1MarketplaceGet(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetMarketplaceDataV1MarketplaceGetQueryResult = NonNullable<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>>
export type GetMarketplaceDataV1MarketplaceGetQueryError = ErrorType<HTTPValidationError>


export function useGetMarketplaceDataV1MarketplaceGet<TData = Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetMarketplaceDataV1MarketplaceGetParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>,
          TError,
          Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetMarketplaceDataV1MarketplaceGet<TData = Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetMarketplaceDataV1MarketplaceGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>,
          TError,
          Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetMarketplaceDataV1MarketplaceGet<TData = Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetMarketplaceDataV1MarketplaceGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Marketplace Data
 */

export function useGetMarketplaceDataV1MarketplaceGet<TData = Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetMarketplaceDataV1MarketplaceGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetMarketplaceDataV1MarketplaceGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getGetMarketplaceDataV1MarketplaceGetSuspenseQueryOptions = <TData = Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError = ErrorType<HTTPValidationError>>(params: GetMarketplaceDataV1MarketplaceGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMarketplaceDataV1MarketplaceGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>> = ({ signal }) => getMarketplaceDataV1MarketplaceGet(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError, TData> & { queryKey: DataTag<QueryKey, TData, TError> }
}

export type GetMarketplaceDataV1MarketplaceGetSuspenseQueryResult = NonNullable<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>>
export type GetMarketplaceDataV1MarketplaceGetSuspenseQueryError = ErrorType<HTTPValidationError>


export function useGetMarketplaceDataV1MarketplaceGetSuspense<TData = Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetMarketplaceDataV1MarketplaceGetParams, options: { query:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetMarketplaceDataV1MarketplaceGetSuspense<TData = Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetMarketplaceDataV1MarketplaceGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
export function useGetMarketplaceDataV1MarketplaceGetSuspense<TData = Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetMarketplaceDataV1MarketplaceGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> }
/**
 * @summary Get Marketplace Data
 */

export function useGetMarketplaceDataV1MarketplaceGetSuspense<TData = Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetMarketplaceDataV1MarketplaceGetParams, options?: { query?:Partial<UseSuspenseQueryOptions<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> } {

  const queryOptions = getGetMarketplaceDataV1MarketplaceGetSuspenseQueryOptions(params,options)

  const query = useSuspenseQuery(queryOptions) as  UseSuspenseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData, TError> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Bulk Add Tags to Offers
 * @summary Add Tags To Offers
 */
export const addTagsToOffersV1MarketplaceBulkOffersTagsPost = (
    bulkAddTagsToOffersInput: BodyType<BulkAddTagsToOffersInput>,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<unknown>(
      {url: `/v1/marketplace/bulk/offers/tags/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: bulkAddTagsToOffersInput, signal
    },
      options);
    }
  


export const getAddTagsToOffersV1MarketplaceBulkOffersTagsPostMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addTagsToOffersV1MarketplaceBulkOffersTagsPost>>, TError,{data: BodyType<BulkAddTagsToOffersInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof addTagsToOffersV1MarketplaceBulkOffersTagsPost>>, TError,{data: BodyType<BulkAddTagsToOffersInput>}, TContext> => {
    
const mutationKey = ['addTagsToOffersV1MarketplaceBulkOffersTagsPost'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addTagsToOffersV1MarketplaceBulkOffersTagsPost>>, {data: BodyType<BulkAddTagsToOffersInput>}> = (props) => {
          const {data} = props ?? {};

          return  addTagsToOffersV1MarketplaceBulkOffersTagsPost(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddTagsToOffersV1MarketplaceBulkOffersTagsPostMutationResult = NonNullable<Awaited<ReturnType<typeof addTagsToOffersV1MarketplaceBulkOffersTagsPost>>>
    export type AddTagsToOffersV1MarketplaceBulkOffersTagsPostMutationBody = BodyType<BulkAddTagsToOffersInput>
    export type AddTagsToOffersV1MarketplaceBulkOffersTagsPostMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Add Tags To Offers
 */
export const useAddTagsToOffersV1MarketplaceBulkOffersTagsPost = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addTagsToOffersV1MarketplaceBulkOffersTagsPost>>, TError,{data: BodyType<BulkAddTagsToOffersInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof addTagsToOffersV1MarketplaceBulkOffersTagsPost>>,
        TError,
        {data: BodyType<BulkAddTagsToOffersInput>},
        TContext
      > => {

      const mutationOptions = getAddTagsToOffersV1MarketplaceBulkOffersTagsPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Bulk change offers status
 * @summary Change Offers Status
 */
export const changeOffersStatusV1MarketplaceBulkOffersStatusPut = (
    bulkChangeOffersStatusInput: BodyType<BulkChangeOffersStatusInput>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/v1/marketplace/bulk/offers/status/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: bulkChangeOffersStatusInput
    },
      options);
    }
  


export const getChangeOffersStatusV1MarketplaceBulkOffersStatusPutMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changeOffersStatusV1MarketplaceBulkOffersStatusPut>>, TError,{data: BodyType<BulkChangeOffersStatusInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof changeOffersStatusV1MarketplaceBulkOffersStatusPut>>, TError,{data: BodyType<BulkChangeOffersStatusInput>}, TContext> => {
    
const mutationKey = ['changeOffersStatusV1MarketplaceBulkOffersStatusPut'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof changeOffersStatusV1MarketplaceBulkOffersStatusPut>>, {data: BodyType<BulkChangeOffersStatusInput>}> = (props) => {
          const {data} = props ?? {};

          return  changeOffersStatusV1MarketplaceBulkOffersStatusPut(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ChangeOffersStatusV1MarketplaceBulkOffersStatusPutMutationResult = NonNullable<Awaited<ReturnType<typeof changeOffersStatusV1MarketplaceBulkOffersStatusPut>>>
    export type ChangeOffersStatusV1MarketplaceBulkOffersStatusPutMutationBody = BodyType<BulkChangeOffersStatusInput>
    export type ChangeOffersStatusV1MarketplaceBulkOffersStatusPutMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Change Offers Status
 */
export const useChangeOffersStatusV1MarketplaceBulkOffersStatusPut = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changeOffersStatusV1MarketplaceBulkOffersStatusPut>>, TError,{data: BodyType<BulkChangeOffersStatusInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof changeOffersStatusV1MarketplaceBulkOffersStatusPut>>,
        TError,
        {data: BodyType<BulkChangeOffersStatusInput>},
        TContext
      > => {

      const mutationOptions = getChangeOffersStatusV1MarketplaceBulkOffersStatusPutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Create Business Rules
 * @summary Create Business Rule
 */
export const createBusinessRuleV1MarketplaceBusinessRulesPost = (
    createBusinessRuleInput: BodyType<CreateBusinessRuleInput>,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<BusinessRulesCollectionModel>(
      {url: `/v1/marketplace/business_rules/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createBusinessRuleInput, signal
    },
      options);
    }
  


export const getCreateBusinessRuleV1MarketplaceBusinessRulesPostMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createBusinessRuleV1MarketplaceBusinessRulesPost>>, TError,{data: BodyType<CreateBusinessRuleInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createBusinessRuleV1MarketplaceBusinessRulesPost>>, TError,{data: BodyType<CreateBusinessRuleInput>}, TContext> => {
    
const mutationKey = ['createBusinessRuleV1MarketplaceBusinessRulesPost'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createBusinessRuleV1MarketplaceBusinessRulesPost>>, {data: BodyType<CreateBusinessRuleInput>}> = (props) => {
          const {data} = props ?? {};

          return  createBusinessRuleV1MarketplaceBusinessRulesPost(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateBusinessRuleV1MarketplaceBusinessRulesPostMutationResult = NonNullable<Awaited<ReturnType<typeof createBusinessRuleV1MarketplaceBusinessRulesPost>>>
    export type CreateBusinessRuleV1MarketplaceBusinessRulesPostMutationBody = BodyType<CreateBusinessRuleInput>
    export type CreateBusinessRuleV1MarketplaceBusinessRulesPostMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Business Rule
 */
export const useCreateBusinessRuleV1MarketplaceBusinessRulesPost = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createBusinessRuleV1MarketplaceBusinessRulesPost>>, TError,{data: BodyType<CreateBusinessRuleInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createBusinessRuleV1MarketplaceBusinessRulesPost>>,
        TError,
        {data: BodyType<CreateBusinessRuleInput>},
        TContext
      > => {

      const mutationOptions = getCreateBusinessRuleV1MarketplaceBusinessRulesPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update Business Rule
 * @summary Update Business Rule Reward Order
 */
export const updateBusinessRuleRewardOrderV1MarketplaceBusinessRulesRuleIdRewardOrderPut = (
    ruleId: string,
    rewardOrderUpdate: BodyType<RewardOrderUpdate>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<BusinessRulesCollectionModel>(
      {url: `/v1/marketplace/business_rules/${ruleId}/reward_order/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: rewardOrderUpdate
    },
      options);
    }
  


export const getUpdateBusinessRuleRewardOrderV1MarketplaceBusinessRulesRuleIdRewardOrderPutMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateBusinessRuleRewardOrderV1MarketplaceBusinessRulesRuleIdRewardOrderPut>>, TError,{ruleId: string;data: BodyType<RewardOrderUpdate>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateBusinessRuleRewardOrderV1MarketplaceBusinessRulesRuleIdRewardOrderPut>>, TError,{ruleId: string;data: BodyType<RewardOrderUpdate>}, TContext> => {
    
const mutationKey = ['updateBusinessRuleRewardOrderV1MarketplaceBusinessRulesRuleIdRewardOrderPut'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateBusinessRuleRewardOrderV1MarketplaceBusinessRulesRuleIdRewardOrderPut>>, {ruleId: string;data: BodyType<RewardOrderUpdate>}> = (props) => {
          const {ruleId,data} = props ?? {};

          return  updateBusinessRuleRewardOrderV1MarketplaceBusinessRulesRuleIdRewardOrderPut(ruleId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateBusinessRuleRewardOrderV1MarketplaceBusinessRulesRuleIdRewardOrderPutMutationResult = NonNullable<Awaited<ReturnType<typeof updateBusinessRuleRewardOrderV1MarketplaceBusinessRulesRuleIdRewardOrderPut>>>
    export type UpdateBusinessRuleRewardOrderV1MarketplaceBusinessRulesRuleIdRewardOrderPutMutationBody = BodyType<RewardOrderUpdate>
    export type UpdateBusinessRuleRewardOrderV1MarketplaceBusinessRulesRuleIdRewardOrderPutMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Update Business Rule Reward Order
 */
export const useUpdateBusinessRuleRewardOrderV1MarketplaceBusinessRulesRuleIdRewardOrderPut = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateBusinessRuleRewardOrderV1MarketplaceBusinessRulesRuleIdRewardOrderPut>>, TError,{ruleId: string;data: BodyType<RewardOrderUpdate>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateBusinessRuleRewardOrderV1MarketplaceBusinessRulesRuleIdRewardOrderPut>>,
        TError,
        {ruleId: string;data: BodyType<RewardOrderUpdate>},
        TContext
      > => {

      const mutationOptions = getUpdateBusinessRuleRewardOrderV1MarketplaceBusinessRulesRuleIdRewardOrderPutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete Business rule
 * @summary Delete Business Rule
 */
export const deleteBusinessRuleV1MarketplaceBusinessRulesRuleIdDelete = (
    ruleId: string,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/v1/marketplace/business_rules/${ruleId}/`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteBusinessRuleV1MarketplaceBusinessRulesRuleIdDeleteMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteBusinessRuleV1MarketplaceBusinessRulesRuleIdDelete>>, TError,{ruleId: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteBusinessRuleV1MarketplaceBusinessRulesRuleIdDelete>>, TError,{ruleId: string}, TContext> => {
    
const mutationKey = ['deleteBusinessRuleV1MarketplaceBusinessRulesRuleIdDelete'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteBusinessRuleV1MarketplaceBusinessRulesRuleIdDelete>>, {ruleId: string}> = (props) => {
          const {ruleId} = props ?? {};

          return  deleteBusinessRuleV1MarketplaceBusinessRulesRuleIdDelete(ruleId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteBusinessRuleV1MarketplaceBusinessRulesRuleIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteBusinessRuleV1MarketplaceBusinessRulesRuleIdDelete>>>
    
    export type DeleteBusinessRuleV1MarketplaceBusinessRulesRuleIdDeleteMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Delete Business Rule
 */
export const useDeleteBusinessRuleV1MarketplaceBusinessRulesRuleIdDelete = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteBusinessRuleV1MarketplaceBusinessRulesRuleIdDelete>>, TError,{ruleId: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteBusinessRuleV1MarketplaceBusinessRulesRuleIdDelete>>,
        TError,
        {ruleId: string},
        TContext
      > => {

      const mutationOptions = getDeleteBusinessRuleV1MarketplaceBusinessRulesRuleIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Create Tag
 * @summary Create Tag
 */
export const createTagV1MarketplaceTagsPost = (
    createTagInput: BodyType<CreateTagInput>,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TagModel>(
      {url: `/v1/marketplace/tags/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createTagInput, signal
    },
      options);
    }
  


export const getCreateTagV1MarketplaceTagsPostMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTagV1MarketplaceTagsPost>>, TError,{data: BodyType<CreateTagInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createTagV1MarketplaceTagsPost>>, TError,{data: BodyType<CreateTagInput>}, TContext> => {
    
const mutationKey = ['createTagV1MarketplaceTagsPost'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTagV1MarketplaceTagsPost>>, {data: BodyType<CreateTagInput>}> = (props) => {
          const {data} = props ?? {};

          return  createTagV1MarketplaceTagsPost(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateTagV1MarketplaceTagsPostMutationResult = NonNullable<Awaited<ReturnType<typeof createTagV1MarketplaceTagsPost>>>
    export type CreateTagV1MarketplaceTagsPostMutationBody = BodyType<CreateTagInput>
    export type CreateTagV1MarketplaceTagsPostMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Tag
 */
export const useCreateTagV1MarketplaceTagsPost = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTagV1MarketplaceTagsPost>>, TError,{data: BodyType<CreateTagInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createTagV1MarketplaceTagsPost>>,
        TError,
        {data: BodyType<CreateTagInput>},
        TContext
      > => {

      const mutationOptions = getCreateTagV1MarketplaceTagsPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update Tags
 * @summary Update Tag
 */
export const updateTagV1MarketplaceTagsTagIdPut = (
    tagId: string,
    rewardOrderUpdate: BodyType<RewardOrderUpdate>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<TagModel>(
      {url: `/v1/marketplace/tags/${tagId}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: rewardOrderUpdate
    },
      options);
    }
  


export const getUpdateTagV1MarketplaceTagsTagIdPutMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTagV1MarketplaceTagsTagIdPut>>, TError,{tagId: string;data: BodyType<RewardOrderUpdate>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTagV1MarketplaceTagsTagIdPut>>, TError,{tagId: string;data: BodyType<RewardOrderUpdate>}, TContext> => {
    
const mutationKey = ['updateTagV1MarketplaceTagsTagIdPut'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTagV1MarketplaceTagsTagIdPut>>, {tagId: string;data: BodyType<RewardOrderUpdate>}> = (props) => {
          const {tagId,data} = props ?? {};

          return  updateTagV1MarketplaceTagsTagIdPut(tagId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateTagV1MarketplaceTagsTagIdPutMutationResult = NonNullable<Awaited<ReturnType<typeof updateTagV1MarketplaceTagsTagIdPut>>>
    export type UpdateTagV1MarketplaceTagsTagIdPutMutationBody = BodyType<RewardOrderUpdate>
    export type UpdateTagV1MarketplaceTagsTagIdPutMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Update Tag
 */
export const useUpdateTagV1MarketplaceTagsTagIdPut = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTagV1MarketplaceTagsTagIdPut>>, TError,{tagId: string;data: BodyType<RewardOrderUpdate>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateTagV1MarketplaceTagsTagIdPut>>,
        TError,
        {tagId: string;data: BodyType<RewardOrderUpdate>},
        TContext
      > => {

      const mutationOptions = getUpdateTagV1MarketplaceTagsTagIdPutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete Tags
 * @summary Delete Tag
 */
export const deleteTagV1MarketplaceTagsTagIdDelete = (
    tagId: string,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/v1/marketplace/tags/${tagId}/`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteTagV1MarketplaceTagsTagIdDeleteMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTagV1MarketplaceTagsTagIdDelete>>, TError,{tagId: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTagV1MarketplaceTagsTagIdDelete>>, TError,{tagId: string}, TContext> => {
    
const mutationKey = ['deleteTagV1MarketplaceTagsTagIdDelete'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTagV1MarketplaceTagsTagIdDelete>>, {tagId: string}> = (props) => {
          const {tagId} = props ?? {};

          return  deleteTagV1MarketplaceTagsTagIdDelete(tagId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTagV1MarketplaceTagsTagIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTagV1MarketplaceTagsTagIdDelete>>>
    
    export type DeleteTagV1MarketplaceTagsTagIdDeleteMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Delete Tag
 */
export const useDeleteTagV1MarketplaceTagsTagIdDelete = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTagV1MarketplaceTagsTagIdDelete>>, TError,{tagId: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteTagV1MarketplaceTagsTagIdDelete>>,
        TError,
        {tagId: string},
        TContext
      > => {

      const mutationOptions = getDeleteTagV1MarketplaceTagsTagIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Re-order the categories by providing a list of Ids in the order that they need to be stored.
 * @summary Reorder Categories
 */
export const reorderCategoriesV1MarketplaceCategoriesReorderPut = (
    reorderCategoriesV1MarketplaceCategoriesReorderPutBody: BodyType<string[]>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/v1/marketplace/categories/reorder/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: reorderCategoriesV1MarketplaceCategoriesReorderPutBody
    },
      options);
    }
  


export const getReorderCategoriesV1MarketplaceCategoriesReorderPutMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reorderCategoriesV1MarketplaceCategoriesReorderPut>>, TError,{data: BodyType<string[]>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof reorderCategoriesV1MarketplaceCategoriesReorderPut>>, TError,{data: BodyType<string[]>}, TContext> => {
    
const mutationKey = ['reorderCategoriesV1MarketplaceCategoriesReorderPut'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reorderCategoriesV1MarketplaceCategoriesReorderPut>>, {data: BodyType<string[]>}> = (props) => {
          const {data} = props ?? {};

          return  reorderCategoriesV1MarketplaceCategoriesReorderPut(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ReorderCategoriesV1MarketplaceCategoriesReorderPutMutationResult = NonNullable<Awaited<ReturnType<typeof reorderCategoriesV1MarketplaceCategoriesReorderPut>>>
    export type ReorderCategoriesV1MarketplaceCategoriesReorderPutMutationBody = BodyType<string[]>
    export type ReorderCategoriesV1MarketplaceCategoriesReorderPutMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Reorder Categories
 */
export const useReorderCategoriesV1MarketplaceCategoriesReorderPut = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reorderCategoriesV1MarketplaceCategoriesReorderPut>>, TError,{data: BodyType<string[]>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof reorderCategoriesV1MarketplaceCategoriesReorderPut>>,
        TError,
        {data: BodyType<string[]>},
        TContext
      > => {

      const mutationOptions = getReorderCategoriesV1MarketplaceCategoriesReorderPutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update category
 * @summary Update Category
 */
export const updateCategoryV1MarketplaceCategoriesCategoryIdPut = (
    categoryId: string,
    categoryRewardOrder: BodyType<CategoryRewardOrder>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<CategoryMarketModel>(
      {url: `/v1/marketplace/categories/${categoryId}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: categoryRewardOrder
    },
      options);
    }
  


export const getUpdateCategoryV1MarketplaceCategoriesCategoryIdPutMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCategoryV1MarketplaceCategoriesCategoryIdPut>>, TError,{categoryId: string;data: BodyType<CategoryRewardOrder>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateCategoryV1MarketplaceCategoriesCategoryIdPut>>, TError,{categoryId: string;data: BodyType<CategoryRewardOrder>}, TContext> => {
    
const mutationKey = ['updateCategoryV1MarketplaceCategoriesCategoryIdPut'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCategoryV1MarketplaceCategoriesCategoryIdPut>>, {categoryId: string;data: BodyType<CategoryRewardOrder>}> = (props) => {
          const {categoryId,data} = props ?? {};

          return  updateCategoryV1MarketplaceCategoriesCategoryIdPut(categoryId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateCategoryV1MarketplaceCategoriesCategoryIdPutMutationResult = NonNullable<Awaited<ReturnType<typeof updateCategoryV1MarketplaceCategoriesCategoryIdPut>>>
    export type UpdateCategoryV1MarketplaceCategoriesCategoryIdPutMutationBody = BodyType<CategoryRewardOrder>
    export type UpdateCategoryV1MarketplaceCategoriesCategoryIdPutMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Update Category
 */
export const useUpdateCategoryV1MarketplaceCategoriesCategoryIdPut = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCategoryV1MarketplaceCategoriesCategoryIdPut>>, TError,{categoryId: string;data: BodyType<CategoryRewardOrder>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateCategoryV1MarketplaceCategoriesCategoryIdPut>>,
        TError,
        {categoryId: string;data: BodyType<CategoryRewardOrder>},
        TContext
      > => {

      const mutationOptions = getUpdateCategoryV1MarketplaceCategoriesCategoryIdPutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Create/Update and Delete highlighted collections. This call will take all highlighted collections that need to be created or updated. All collections that are not in the list will be deleted.
 * @summary Create Update Delete Highlighted Collection
 */
export const createUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPost = (
    highlightedCollectionModelInput: BodyType<HighlightedCollectionModelInput[]>,
    params: CreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<CreatedUpdatedDeleted>(
      {url: `/v1/marketplace/highlighted_collections/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: highlightedCollectionModelInput,
        params, signal
    },
      options);
    }
  


export const getCreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPost>>, TError,{data: BodyType<HighlightedCollectionModelInput[]>;params: CreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostParams}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPost>>, TError,{data: BodyType<HighlightedCollectionModelInput[]>;params: CreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostParams}, TContext> => {
    
const mutationKey = ['createUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPost'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPost>>, {data: BodyType<HighlightedCollectionModelInput[]>;params: CreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostParams}> = (props) => {
          const {data,params} = props ?? {};

          return  createUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPost(data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostMutationResult = NonNullable<Awaited<ReturnType<typeof createUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPost>>>
    export type CreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostMutationBody = BodyType<HighlightedCollectionModelInput[]>
    export type CreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Update Delete Highlighted Collection
 */
export const useCreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPost = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPost>>, TError,{data: BodyType<HighlightedCollectionModelInput[]>;params: CreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostParams}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPost>>,
        TError,
        {data: BodyType<HighlightedCollectionModelInput[]>;params: CreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostParams},
        TContext
      > => {

      const mutationOptions = getCreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Create/Update and Delete recommended items. This call will take all recommended items that need to be created or updated. All recommended items that are not in the list will be deleted.
 * @summary Create Update Delete Recommended
 */
export const createUpdateDeleteRecommendedV1MarketplaceRecommendedPost = (
    createUpdateDeleteRecommendedV1MarketplaceRecommendedPostBodyItem: BodyType<CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostBodyItem[]>,
    params: CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<CreatedUpdatedDeleted>(
      {url: `/v1/marketplace/recommended/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createUpdateDeleteRecommendedV1MarketplaceRecommendedPostBodyItem,
        params, signal
    },
      options);
    }
  


export const getCreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUpdateDeleteRecommendedV1MarketplaceRecommendedPost>>, TError,{data: BodyType<CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostBodyItem[]>;params: CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostParams}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createUpdateDeleteRecommendedV1MarketplaceRecommendedPost>>, TError,{data: BodyType<CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostBodyItem[]>;params: CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostParams}, TContext> => {
    
const mutationKey = ['createUpdateDeleteRecommendedV1MarketplaceRecommendedPost'];
const {mutation: mutationOptions, request: requestOptions} = options ?
      options.mutation && 'mutationKey' in options.mutation && options.mutation.mutationKey ?
      options
      : {...options, mutation: {...options.mutation, mutationKey}}
      : {mutation: { mutationKey, }, request: undefined};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUpdateDeleteRecommendedV1MarketplaceRecommendedPost>>, {data: BodyType<CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostBodyItem[]>;params: CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostParams}> = (props) => {
          const {data,params} = props ?? {};

          return  createUpdateDeleteRecommendedV1MarketplaceRecommendedPost(data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostMutationResult = NonNullable<Awaited<ReturnType<typeof createUpdateDeleteRecommendedV1MarketplaceRecommendedPost>>>
    export type CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostMutationBody = BodyType<CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostBodyItem[]>
    export type CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Update Delete Recommended
 */
export const useCreateUpdateDeleteRecommendedV1MarketplaceRecommendedPost = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUpdateDeleteRecommendedV1MarketplaceRecommendedPost>>, TError,{data: BodyType<CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostBodyItem[]>;params: CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostParams}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createUpdateDeleteRecommendedV1MarketplaceRecommendedPost>>,
        TError,
        {data: BodyType<CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostBodyItem[]>;params: CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostParams},
        TContext
      > => {

      const mutationOptions = getCreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    