import React, { ReactNode } from "react";
import { useState } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";

import Tab from "@/tab";
import { Card } from "@/ui/card";

import {
  getGetMarketConfigByIdV1MarketConfigMarketConfigIdGetSuspenseQueryOptions,
  useDeleteMarketConfigV1MarketConfigMarketConfigIdDelete,
  useUpdateMarketConfigV1MarketConfigMarketConfigIdPatch,
} from "~/api/market-config/market-config.gen";
import { ImageType, MarketConfigModelInput, SocialMediaSubModel } from "~/api/model";
import { getCountryName, useGetCountryOptions } from "~/helpers/country-list";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { Form } from "~/oldComponents/form/Form";
import { Field } from "~/oldComponents/form/types";

export const Route = createFileRoute("/_auth/markets/$marketId/")({
  loader: ({ context: { queryClient }, params: { marketId } }) => {
    return queryClient.ensureQueryData(getGetMarketConfigByIdV1MarketConfigMarketConfigIdGetSuspenseQueryOptions(marketId));
  },
  component: () => <UpdateMarketPage />,
});

function UpdateMarketPage() {
  const navigate = useNavigate();

  const marketId = Route.useParams().marketId;

  const { data: market, refetch } = useSuspenseQuery(getGetMarketConfigByIdV1MarketConfigMarketConfigIdGetSuspenseQueryOptions(marketId));

  const [changeMarket, setChangeMarket] = useState<MarketConfigModelInput>(market);

  const updateMarketMutation = useUpdateMarketConfigV1MarketConfigMarketConfigIdPatch({
    mutation: {
      onSuccess: async () => {
        toast("market successfully updated!");
        await refetch();
        navigate({ to: "/markets" });
      },
      onError: (error: AxiosError) => {
        toast.error("Error", {
          description: parseFastAPIError(error) as ReactNode,
        });
      },
    },
  });

  const deleteMarketMutation = useDeleteMarketConfigV1MarketConfigMarketConfigIdDelete({
    mutation: {
      onSuccess: () => {
        toast("market successfully deleted!");
        navigate({ to: "/markets" });
      },
      onError: (error: AxiosError) => {
        toast.error("Error", {
          description: parseFastAPIError(error) as ReactNode,
        });
      },
    },
  });

  const onMarketDelete = async () => {
    await deleteMarketMutation.mutateAsync({ marketConfigId: marketId });
  };

  const onSubmit = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();
    if (!market) return;

    await updateMarketMutation.mutateAsync({
      marketConfigId: marketId,
      data: changeMarket,
    });
  };

  const rewardFields: Field[] = [
    {
      name: "country",
      label: "Country:",
      type: "search-select",
      helpText: "Specify market country.",
      change: (data: string) => {
        setChangeMarket((prev) => ({ ...prev, countryCode: data }));
      },
      value: changeMarket?.countryCode,
      options: useGetCountryOptions(),
      row: true,
      required: true,
    },
    {
      name: "releaseStatus",
      label: "Release status:",
      helpText: "Flag that indicates availability of the market for mobile apps.",
      type: "checkbox",
      change: () => {
        setChangeMarket((prev) => ({
          ...prev,
          readyForRelease: !changeMarket.readyForRelease,
        }));
      },
      value: changeMarket?.readyForRelease,
      row: true,
    },
    {
      name: "supportEmails",
      label: "Support emails:",
      helpText:
        "Support emails block is responsible for service emails. Users specified in this list will receive emails about:\n1) offers that are scheduled for unpublishing in the next 24h;\n2) offers which coupon code warning limit was reached;\n3) offers which coupon codes are run out.",
      required: true,
      type: "text-array",
      change: (newArray: string[]) => {
        setChangeMarket((prev) => ({ ...prev, supportEmails: newArray }));
      },
      value: changeMarket?.supportEmails || [],
      row: true,
      custom: true,
    },
    {
      name: "instagram",
      label: "Instagram:",
      type: "text",
      helpText: "Stepler Instagram account represented in specified country.",
      value: changeMarket?.socialMedia.find((social: SocialMediaSubModel) => social.source === "Instagram")?.url,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const socialmedia = [...changeMarket.socialMedia];
        const index = socialmedia.findIndex((social: SocialMediaSubModel) => social.source === "Instagram");
        if (index !== -1) {
          socialmedia[index].url = event.target.value;
        } else {
          socialmedia.push({ source: "Instagram", url: event.target.value });
        }
        setChangeMarket((prev) => ({ ...prev, socialMedia: socialmedia }));
      },
      row: true,
    },
    {
      name: "facebook",
      label: "Facebook:",
      type: "text",
      helpText: "Stepler Facebook account represented in specified country.",
      value: changeMarket?.socialMedia.find((social: SocialMediaSubModel) => social.source === "Facebook")?.url,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const socialmedia = [...changeMarket.socialMedia];
        const index = socialmedia.findIndex((social: SocialMediaSubModel) => social.source === "Facebook");
        if (index !== -1) {
          socialmedia[index].url = event.target.value;
        } else {
          socialmedia.push({ source: "Facebook", url: event.target.value });
        }
        setChangeMarket((prev) => ({ ...prev, socialMedia: socialmedia }));
      },
      row: true,
    },
    {
      name: "tiktok",
      label: "TikTok:",
      type: "text",
      helpText: "Stepler TikTok account represented in specified country.",
      value: changeMarket?.socialMedia.find((social: SocialMediaSubModel) => social.source === "TikTok")?.url,
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const socialmedia = [...changeMarket.socialMedia];
        const index = socialmedia.findIndex((social: SocialMediaSubModel) => social.source === "TikTok");
        if (index !== -1) {
          socialmedia[index].url = event.target.value;
        } else {
          socialmedia.push({ source: "TikTok", url: event.target.value });
        }
        setChangeMarket((prev) => ({ ...prev, socialMedia: socialmedia }));
      },
      row: true,
    },
    {
      name: "banner",
      label: "Banner:",
      helpText: "Market banner. Displayed on app dashboard.",
      type: "image-cropper",
      imageType: ImageType.market_banner,
      value: changeMarket?.bannerUrl,
      change: (imageUrl: string | null) => {
        setChangeMarket((prev) => ({ ...prev, bannerUrl: imageUrl }));
      },
      row: true,
      custom: true,
    },
  ];

  return (
    <section className="flex flex-col min-h-full gap-10 bg-background px-8 py-10">
      <section className="flex items-start justify-between">
        <div className="header-titles_wrapper gap-4">
          <h2 className="text-foreground">Edit Market {getCountryName(market.countryCode)}</h2>
        </div>
        <div>
          <button onClick={onMarketDelete} className="button button--danger">
            Delete market
          </button>
        </div>
      </section>
      <div>
        <div className="flex w-full gap-2">
          <Tab active to={`/markets/${market._id}`} title="General" />
          <Tab active={false} to={`/markets/${market._id}/rewards`} title="Rewards" />
          <Tab active={false} to={`/markets/${market._id}/leaderboards`} title="Leaderboard" />
        </div>
        <Card className="bg-card px-4 py-10 text-card-foreground">
          <Form vertical name="rewards" onSubmit={onSubmit} submitText="Save market" fields={rewardFields} />
        </Card>
      </div>
    </section>
  );
}
