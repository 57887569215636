import * as React from "react";
import PropTypes from "prop-types";

import { cn } from "~/lib/utils";

const Input = React.forwardRef<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>(({ className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(
        "flex w-full rounded-md bg-background px-4 py-2.5 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-hidden disabled:cursor-not-allowed disabled:opacity-50",
        className,
      )}
      ref={ref}
      {...props}
    />
  );
});
Input.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(["text", "email", "password", "number", "tel", "url"]),
};
Input.displayName = "Input";

export { Input };
