import React from 'react'
import { createFileRoute } from '@tanstack/react-router'
import { toast } from 'sonner'

import { useGetInviteLinksV1InviteLinksGet } from '~/api/invite-links/invite-links.gen'
import { InviteLinkWithInstalls } from '~/api/model'
import Button from '~/oldComponents/button/Button'
import { ListPage } from '~/oldComponents/layout/ListPage'
import { PageConfig, TableConfig } from '~/oldComponents/layout/types'

export const Route = createFileRoute('/_auth/invite-links/')({
  component: () => <InviteLinksPage />,
})

const InviteLinksPage = () => {
  const navigate = Route.useNavigate()
  const { data: inviteLinks, isFetching } =
    useGetInviteLinksV1InviteLinksGet()

  const onCopy = async (link: InviteLinkWithInstalls) => {
    navigator.clipboard.writeText(link.deeplink as string)
    toast('Link was successfully copied to clipboard!')
  }

  const page: PageConfig = {
    title: 'Invite Links',
    description: 'Create new or edit current invite links',
    addLink: '/invite-links/create',
    addContent: 'Create new invite link',
    tableUpload: {
      fileName: 'invite-links',
      data: inviteLinks,
    },
  }

  const table: TableConfig = {
    tableBuild: [
      {
        headerTitle: 'Name',
        format: (item: InviteLinkWithInstalls) => item.name,
      },
      {
        headerTitle: 'Reward',
        format: (item: InviteLinkWithInstalls) => item.reward,
      },
      {
        headerTitle: 'Amount',
        format: (item: InviteLinkWithInstalls) => item?.amount || '-',
      },
      {
        headerTitle: 'Installs',
        format: (item: InviteLinkWithInstalls) =>
          item.installs ? item.installs : '-',
      },
      {
        headerTitle: 'Expire date',
        format: (item: InviteLinkWithInstalls) => item?.expireDate || '-',
      },
      {
        headerTitle: 'Sign up only',
        format: (item: InviteLinkWithInstalls) =>
          item.signUpOnly ? 'Yes' : 'No',
      },
      {
        headerTitle: 'Country',
        format: (item: InviteLinkWithInstalls) => item?.country || '-',
      },
      {
        headerTitle: 'Actions',
        actionRow: true,
        format: (item: InviteLinkWithInstalls) =>
          item.deeplink ? (
            <Button
              title="Copy deep link"
              icon="Copy"
              onClick={() => onCopy(item)}
            />
          ) : (
            '-'
          ),
      },
    ],
    objects: inviteLinks,
    link: (item: InviteLinkWithInstalls) =>
      navigate({
        to: '/invite-links/$inviteLinkId',
        params: { inviteLinkId: item._id?.toString() || '' },
      }),
    emptyMessage: 'There are no invite links in the system yet.',
  }

  return <ListPage loading={isFetching} page={page} table={table} />
}
