import { ReactNode, useCallback, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { toast } from "sonner";

import { parseFastAPIError } from "~/helpers/parse-errors";
import { AuthService } from "~/services/auth/authService";
import { TokenService } from "~/services/auth/tokenService";
import { useAuthStore } from "~/store/authStore";

/**
 * Hook for authentication operations and state
 */
export function useAuth() {
  const {
    accessToken,
    refreshToken,
    user,
    isAuthenticated,
    isAdminOwner,
    setTokens,
    setUser,
    logout: _storeLogout
  } = useAuthStore();

  // Set up token refresh interval
  useEffect(() => {
    if (!isAuthenticated || !accessToken || !refreshToken || !user?.user_id) {
      return;
    }

    // Check token expiration and refresh if needed
    const checkTokenExpiration = async () => {
      try {
        await AuthService.refreshTokenIfNeeded(
          accessToken,
          refreshToken,
          user.user_id
        );
      } catch (error) {
        console.error("Token refresh failed:", error);
      }
    };

    // Initial check
    checkTokenExpiration();

    // Set up interval to check token expiration
    // Check every minute (adjust as needed)
    const intervalId = setInterval(checkTokenExpiration, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [accessToken, refreshToken, user, isAuthenticated]);

  // Send login email mutation
  const loginEmailMutation = useMutation({
    mutationFn: ({ email, loginUrl }: { email: string; loginUrl: string }) =>
      AuthService.sendLoginEmail(email, loginUrl),
    onError: (error: AxiosError) => {
      toast.error("Login failed", {
        description: parseFastAPIError(error) as ReactNode
      });
    }
  });

  // Extract stable values from mutation
  const { mutate } = loginEmailMutation;

  // Handle login with magic link
  const sendLoginEmail = useCallback(
    async (email: string, loginUrl: string) => {
      return mutate({ email, loginUrl });
    },
    [mutate]
  );

  // Handle logout
  const handleLogout = useCallback(async () => {
    await AuthService.logout();
  }, []);

  // Get token expiration time
  const getTokenExpirationTime = useCallback(() => {
    if (!accessToken) return 0;
    return TokenService.getTokenTimeRemaining(accessToken);
  }, [accessToken]);

  // Check if user has specific permission
  const hasPermission = useCallback(
    (permission: string) => {
      if (!accessToken) return false;
      const permissions = TokenService.getPermissionsFromToken(accessToken);
      return permissions.includes(permission);
    },
    [accessToken]
  );

  return {
    // State
    user,
    isAuthenticated,
    isAdminOwner,
    accessToken,
    refreshToken,

    // Actions
    login: sendLoginEmail,
    logout: handleLogout,
    setUser,
    setTokens,

    // Utilities
    hasPermission,
    getTokenExpirationTime,
    isLoading: loginEmailMutation.isPending
  };
}
