import { redirect } from "@tanstack/react-router";
import Axios, { AxiosError, AxiosRequestConfig } from "axios";

import { getBackendUrl } from "~/helpers/backend";
import { AuthService } from "~/services/auth/authService";
import { TokenService } from "~/services/auth/tokenService";
import { useAuthStore } from "~/store/authStore";

export const AXIOS_INSTANCE = Axios.create({ baseURL: getBackendUrl() });

// Request interceptor to validate tokens before sending requests
AXIOS_INSTANCE.interceptors.request.use(
  async (config) => {
    // Skip token validation for auth endpoints
    const isAuthEndpoint = config.url?.includes('/v1/auth/');
    if (isAuthEndpoint) {
      return config;
    }

    const state = useAuthStore.getState();
    const { accessToken, refreshToken, user, isAuthenticated } = state;

    // If not authenticated or missing tokens/user, proceed with request as is
    if (!isAuthenticated || !accessToken || !refreshToken || !user?.user_id) {
      return config;
    }

    // Check if token is expired or will expire soon
    if (TokenService.isTokenExpired(accessToken)) {
      try {
        // Try to refresh the token
        const refreshResult = await AuthService.refreshToken(refreshToken, user.user_id);
        
        // refreshResult is guaranteed to be non-null here because refreshToken would throw if it failed
        if (refreshResult) {
          // Update request headers with new token
          config.headers.Authorization = `Bearer ${refreshResult.accessToken}`;
        }
      } catch (error) {
        // Refresh failed, logout user
        console.error("Token refresh failed in request interceptor:", error);
        state.logout();
        redirect({ to: "/login" });
      }
    }
    
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
AXIOS_INSTANCE.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If error is not 401 or request already retried, reject
    if (error.response?.status !== 401 || originalRequest._retry) {
      return Promise.reject(error);
    }

    // Mark this request as retried
    originalRequest._retry = true;

    try {
      // Use AuthService to handle auth error
      const newAccessToken = await AuthService.handleAuthError(error);
      
      // Update request headers with new token
      originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
      
      // Retry original request
      return AXIOS_INSTANCE(originalRequest);
    } catch (refreshError) {
      // Log the refresh error for debugging
      console.error("Failed to refresh token during response interceptor:", refreshError);
      // Error already handled by AuthService
      return Promise.reject(error);
    }
  },
);

// add a second `options` argument here if you want to pass extra options to each generated query
export const axiosInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({ data }) => data);

  // @ts-expect-error - this is a hack to make sure the promise is cancelable
  promise.cancel = () => {
    source.cancel("Query was cancelled");
  };

  return promise;
};

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<e> = AxiosError<e>;
export type BodyType<BodyData> = BodyData;
