import { jwtDecode } from "jwt-decode";

import { ExtendedJwtPayload } from "~/types/auth";

/**
 * Token Service - Handles token validation, expiration checks, and related utilities
 */
export class TokenService {
  /**
   * Check if a JWT token is expired
   * @param token The JWT token to check
   * @param bufferSeconds Optional buffer time in seconds before actual expiration (default: 60)
   * @returns boolean indicating if the token is expired or will expire within buffer time
   */
  static isTokenExpired(token: string, bufferSeconds = 60): boolean {
    if (!token) return true;
    
    try {
      const decoded = jwtDecode<ExtendedJwtPayload>(token);
      if (!decoded.exp) return true;
      
      // Current time in seconds + buffer
      const currentTime = Math.floor(Date.now() / 1000) + bufferSeconds;
      return decoded.exp < currentTime;
    } catch (error) {
      console.error("Error decoding token:", error);
      return true;
    }
  }

  /**
   * Get time remaining until token expiration in seconds
   * @param token The JWT token
   * @returns Number of seconds until expiration, or 0 if token is invalid/expired
   */
  static getTokenTimeRemaining(token: string): number {
    if (!token) return 0;
    
    try {
      const decoded = jwtDecode<ExtendedJwtPayload>(token);
      if (!decoded.exp) return 0;
      
      const currentTime = Math.floor(Date.now() / 1000);
      const timeRemaining = decoded.exp - currentTime;
      
      return timeRemaining > 0 ? timeRemaining : 0;
    } catch (error) {
      console.error("Error getting token time remaining:", error);
      return 0;
    }
  }

  /**
   * Extract user permissions from token
   * @param token The JWT token
   * @returns Array of permission strings or empty array if invalid
   */
  static getPermissionsFromToken(token: string): string[] {
    if (!token) return [];
    
    try {
      const { permissions } = jwtDecode<ExtendedJwtPayload>(token);
      return permissions || [];
    } catch (error) {
      console.error("Error extracting permissions from token:", error);
      return [];
    }
  }

  /**
   * Safely decode a JWT token with error handling
   * @param token The JWT token to decode
   * @returns Decoded token payload or null if invalid
   */
  static decodeToken(token: string): ExtendedJwtPayload | null {
    if (!token) return null;
    
    try {
      return jwtDecode<ExtendedJwtPayload>(token);
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  }
}
