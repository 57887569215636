import * as React from "react";
import { QueryObserverResult, RefetchOptions, useSuspenseQuery } from "@tanstack/react-query";
import { isAfter, parseISO, subMonths } from "date-fns";
import { toast } from "sonner";

import { IconName } from "@/icon/types";

import { getGetMarketplaceDataV1MarketplaceGetSuspenseQueryOptions } from "~/api/marketplace/marketplace.gen";
import {
  BusinessRulesCollectionModel,
  BusinessRuleType,
  CategoryMarketModel,
  HighlightedCollectionModelOutput,
  OfferStatus,
  OfferType,
  OfferWithCouponCount,
  PlatformSubModel,
  RecommendedModelOutput,
  TagModel,
} from "~/api/model";
import { getCategoryIcon, getCategoryName } from "~/helpers/category";
import { useMarketStore } from "~/store/market";

export type OfferWithCategoriesAndTags = OfferWithCouponCount & {
  id: string | undefined;
  fullTitle: string;
  categories: CategoryMarketModel[];
  tags: TagModel[];
  categoryIds: string[];
  categoryIdsString: string;
  tagIds: string[];
  tagIdStrings: string;
  tagNames: string;
  isBonus: boolean;
  platformString: string;
};
export type CategoryWithStatusAndRewardCount = CategoryMarketModel & {
  status: ["recommended", "highlighted"] | ["recommended"] | ["highlighted"] | [];
  rewardCount: number;
  publishedCount: number;
  unpublishedCount: number;
  name: string;
  icon: IconName;
  rewardOrder: string[];
};
export type TagWithStatusAndRewardCount = TagModel & {
  status: ["recommended", "highlighted"] | ["recommended"] | ["highlighted"] | [];
  rewardCount: number;
  publishedCount: number;
  unpublishedCount: number;
  rewardOrder: string[];
};

export type BusinessRuleWithStatusAndCount = BusinessRulesCollectionModel & {
  status: ["recommended", "highlighted"] | ["recommended"] | ["highlighted"] | [];
  rewardCount: number;
  publishedCount: number;
  unpublishedCount: number;
};

export type HighlightedCollectionWithCollection = HighlightedCollectionModelOutput & {
  id: string | undefined;
  collection: CategoryWithStatusAndRewardCount | TagWithStatusAndRewardCount | BusinessRuleWithStatusAndCount | undefined;
};

export function getPlatformString(platform: PlatformSubModel) {
  if (platform.ios && platform.android) {
    return "both";
  } else if (platform.ios) {
    return "ios";
  } else if (platform.android) {
    return "android";
  } else {
    return "";
  }
}

interface MarketplaceContext {
  rewards: OfferWithCategoriesAndTags[];
  activeRewards: OfferWithCategoriesAndTags[];
  newUserSpecialRewards: OfferWithCategoriesAndTags[];
  almostSoldOutRewards: OfferWithCategoriesAndTags[];
  categories: CategoryWithStatusAndRewardCount[];
  tags: TagWithStatusAndRewardCount[];
  businessRules: BusinessRuleWithStatusAndCount[];
  recommended: RecommendedModelOutput[];
  collections: HighlightedCollectionWithCollection[];
  market: string;
  refetchMarketplace: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<unknown, Error>>;
  marketplaceLoading: boolean;
}

const MarketplaceContext = React.createContext<MarketplaceContext | null>(null);

export function MarketplaceProvider({ children }: { children: React.ReactNode }) {
  const market = useMarketStore((state) => state.market);

  const { data, isFetching, isLoading, refetch } = useSuspenseQuery(getGetMarketplaceDataV1MarketplaceGetSuspenseQueryOptions({ market }));

  function getCOrTStatus(identifier: string, colls: HighlightedCollectionModelOutput[], recs: RecommendedModelOutput[]) {
    const status = [];
    if (colls.find((c: HighlightedCollectionModelOutput) => c.data === identifier)) {
      status.push("highlighted");
    }
    if (recs.find((r: RecommendedModelOutput) => r.data === identifier)) {
      status.push("recommended");
    }

    return status;
  }

  function getBusinessRuleRewards(rule: BusinessRulesCollectionModel, rewards: OfferWithCategoriesAndTags[]) {
    if (rule.type === BusinessRuleType.NewUserSpecial) {
      const brRewards = rewards.filter((r) => rule.rewardOrder?.includes(r.id as string) && r.type === OfferType.NewUserSpecialOffer);
      return brRewards.sort((a, b) => {
        const indexA = rule.rewardOrder?.indexOf(a.id as string) ?? -1;
        const indexB = rule.rewardOrder?.indexOf(b.id as string) ?? -1;
        return indexA - indexB;
      });
    } else if (rule.type === BusinessRuleType.All) {
      return rewards.filter((r) => r.status !== OfferStatus.Archived && r.type !== OfferType.NewUserSpecialOffer && !r.hideInList);
    } else if (rule.type === BusinessRuleType.MostSold) {
      return rewards
        .filter((r) => r.status === OfferStatus.Published && r.type !== OfferType.NewUserSpecialOffer && !r.hideInList && r.type !== OfferType.Bonus)
        .sort((a, b) => (b.couponWarningLimit || 7) - (a.couponWarningLimit || 7))
        .slice(0, rule.amountOfRewards || 0);
    } else {
      return [];
    }
  }

  function getConnectedCollection(
    identifier: string,
    type: string,
    categories: CategoryWithStatusAndRewardCount[],
    tags: TagWithStatusAndRewardCount[],
    rules: BusinessRuleWithStatusAndCount[],
  ) {
    if (type === "Category") {
      return categories.find((c) => c.category === identifier);
    } else if (type === "Tag") {
      return tags.find((t) => t._id === identifier);
    } else if (type === "BusinessRules") {
      return rules.find((b) => b._id === identifier);
    } else {
      toast.info("Unknow collection type");
    }
  }

  const newRewards = React.useMemo(() => data.offers.map((offer) => {
    const rewardCategories = data.categories.filter((category) => category.rewardOrder.includes(offer._id?.toString() || ""));
    const rewardTags = data.tags
      .filter((tag) => tag.rewardOrder?.includes(offer._id?.toString() || ""))
      .sort((a, b) => a.name.localeCompare(b.name));
    return {
      ...offer,
      id: offer._id,
      fullTitle: `${offer.titleV2} - ${offer.subTitle || ""}`,
      categories: rewardCategories,
      tags: rewardTags,
      categoryIds: rewardCategories.map((c) => c.category),
      categoryIdsString: rewardCategories.map((c) => c.category).join(","),
      tagIds: rewardTags.map((t) => t._id),
      tagIdStrings: rewardTags.map((t) => t._id).join(","),
      tagNames: rewardTags.map((t) => t.name).join(","),
      isBonus: offer.type === OfferType.Bonus,
      platformString: getPlatformString(offer.platform),
    } as OfferWithCategoriesAndTags;
  }), [data.offers, data.categories, data.tags])
  const newCategories = React.useMemo(() => data.categories
    .map((category) => {
      return {
        ...category,
        name: getCategoryName(category.category),
        icon: getCategoryIcon(category.category),
        status: getCOrTStatus(category.category, data.highlighted_collections, data.recommended),
        rewardCount: category.rewardOrder.length,
        publishedCount: newRewards.filter((reward) => reward.categoryIds.includes(category.category) && reward.status === OfferStatus.Published)
          .length,
        unpublishedCount: newRewards.filter(
          (reward) => reward.categoryIds.includes(category.category || "") && reward.status === OfferStatus.Unpublished,
        ).length,
      } as CategoryWithStatusAndRewardCount;
    })
    .sort((a, b) => a.category.localeCompare(b.category)), [data.categories, newRewards, data.highlighted_collections, data.recommended]);
  const newTags = React.useMemo(() => data.tags
    .map((tag) => {
      return {
        ...tag,
        status: getCOrTStatus(tag._id as string, data.highlighted_collections, data.recommended),
        rewardCount: tag.rewardOrder?.length || 0,
        publishedCount: newRewards.filter((reward) => reward.tagIds.includes(tag._id as string) && reward.status === OfferStatus.Published).length,
        unpublishedCount: newRewards.filter((reward) => reward.tagIds.includes(tag._id as string) && reward.status === OfferStatus.Unpublished)
          .length,
      } as TagWithStatusAndRewardCount;
    })
    .sort((a, b) => a.name.localeCompare(b.name)), [data.tags, newRewards, data.highlighted_collections, data.recommended]);
  const newBusinessRules = React.useMemo(() => data.business_rules.map((rule) => {
    const ruleRewards = getBusinessRuleRewards(rule, newRewards);

    return {
      ...rule,
      status: getCOrTStatus(rule._id as string, data.highlighted_collections, data.recommended),
      rewardCount: ruleRewards.length,
      publishedCount: ruleRewards.filter((reward) => reward.status === OfferStatus.Published).length,
      unpublishedCount: ruleRewards.filter((reward) => reward.status === OfferStatus.Unpublished).length,
      rewardOrder: ruleRewards.map((r) => r.id),
    } as BusinessRuleWithStatusAndCount;
  }), [data.business_rules, newRewards, data.highlighted_collections, data.recommended]);
  const newCollections = React.useMemo(() => data.highlighted_collections.map((highlightedCollection) => {
    return {
      ...highlightedCollection,
      collection: getConnectedCollection(highlightedCollection.data, highlightedCollection.type, newCategories, newTags, newBusinessRules),
      id: highlightedCollection._id,
    } as HighlightedCollectionWithCollection;
  }), [data.highlighted_collections, newCategories, newTags, newBusinessRules]);
  const newRecommended = React.useMemo(() => data.recommended, [data.recommended]);

  const oneMonthAgo = subMonths(new Date(), 1);

  return (
    <MarketplaceContext.Provider
      value={{
        rewards: newRewards,
        activeRewards: newRewards.filter((r) => r.status !== OfferStatus.Archived),
        newUserSpecialRewards: newRewards.filter((r) => r.status !== OfferStatus.Archived && r.type === OfferType.NewUserSpecialOffer),
        almostSoldOutRewards: newRewards
          .filter((reward) => reward.status !== OfferStatus.Archived)
          .filter((reward) => !reward.isBonus)
          .filter((reward) => reward.updatedAt && isAfter(parseISO(reward.updatedAt), oneMonthAgo))
          .filter(
            (reward) =>
              reward.amount - (reward.verified || 0) < (reward.couponWarningLimit || 7) ||
              (reward.coupon_count &&
                reward.coupon_count > 0 &&
                reward.unused_coupon_count &&
                reward.unused_coupon_count < (reward.couponWarningLimit || 7)),
          ),
        categories: newCategories,
        tags: newTags,
        businessRules: newBusinessRules,
        recommended: newRecommended,
        collections: newCollections,
        refetchMarketplace: refetch,
        marketplaceLoading: isFetching || isLoading,
        market,
      }}
    >
      {children}
    </MarketplaceContext.Provider>
  );
}

export function useMarketplace() {
  const context = React.useContext(MarketplaceContext);
  if (!context) {
    throw new Error("useMarketplace must be used within an MarketplaceProvider");
  }
  return context;
}
