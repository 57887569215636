import React, { useEffect, useState } from 'react';
import { Terminal } from 'lucide-react';

import { Alert, AlertDescription, AlertTitle } from './ui/alert';
import { Button } from './ui/button';

interface VersionData {
  gitHash: string;
}

const VersionChecker: React.FC = () => {
  const [isOutOfDate, setIsOutOfDate] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchVersion = async () => {
      try {
        // Add timestamp to URL to prevent caching
        const timestamp = new Date().getTime();
        const versionUrl = `/version.json?t=${timestamp}`;

        // Add cache control headers to prevent caching
        const response = await fetch(versionUrl, {
          cache: 'no-store',
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
          }
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data: VersionData = await response.json();

        // Compare with the git hash injected by vite (see below)
        if (import.meta.env.VITE_GIT_HASH && data.gitHash !== import.meta.env.VITE_GIT_HASH) {
          setIsOutOfDate(true);
        } else {
          setIsOutOfDate(false);
        }
      } catch (e) {
        console.error('Failed to fetch version:', e);
        setError(e instanceof Error ? e.message : 'Failed to fetch version data.');
      }
    };

    fetchVersion();

    // Optionally, check periodically (e.g., every hour)
    const intervalId = setInterval(fetchVersion, 300000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  if (error) {
    return null;
  }

  if (isOutOfDate) {
    return (
      <Alert className="fixed top-4 right-4 z-50 w-96">
        <Terminal className="w-4 h-4" />
        <AlertTitle>New version</AlertTitle>
        <AlertDescription className="flex flex-col gap-8 items-stretch">
          <span>A new version of the application is available. Please refresh the page to get the latest updates.</span>
          <Button onClick={() => window.location.reload()}>Refresh</Button>
        </AlertDescription>
      </Alert>
    );
  }

  return null;
};

export default VersionChecker;
