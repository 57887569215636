import { ReactNode } from "react";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";

interface Error {
  loc?: string[];
  msg: string;
  type: string;
}

const getMessage = (detail: Error[]): string => {
  if (!detail || !detail.length) return "Something went wrong!";
  
  return detail.map(err => err.msg).join(", ");
};

const parseFastAPIError = (error: AxiosError): ReactNode => {
  // Log that there was an error
  Sentry.captureException(error);

  const { response } = error;
  if (!response) return "Something went wrong!";

  const data = response.data as { detail: Error[] } | null;
  if (!data) return "Something went wrong!";

  const detail = data.detail;
  if (response.status === 422) {
    return getMessage(detail);
  }

  if (response.status === 404) {
    return getMessage(detail);
  }

  if (response.status === 400) {
    return getMessage(detail);
  }

  return "Something went wrong!";
};

export { parseFastAPIError };
