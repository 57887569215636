 import React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { CheckIcon, ChevronDown, WandSparkles, XCircle, XIcon } from "lucide-react";

import { Badge } from "@/ui/badge";
import { Button } from "@/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator } from "@/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/ui/popover";
import { Separator } from "@/ui/separator";

import { cn } from "~/lib/utils";

const multiSelectVariants = cva("transition ease-in-out text-foreground/75 hover:text-foreground duration-300", {
  variants: {
    variant: {
      default: "border-foreground/10 drop-shadow-md bg-background hover:bg-card/80",
      secondary: "border-foreground/10 bg-secondary text-secondary-foreground hover:bg-secondary/80",
      destructive: "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
      inverted: "inverted",
      reverse: "flex-row-reverse justify-between",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

type MultiSelectOption = {
  label: string;
  value: string;
  icon?: React.ComponentType<{ className?: string }>;
};

interface MultiSelectFormFieldProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof multiSelectVariants> {
  asChild?: boolean;
  options: MultiSelectOption[];
  defaultValue?: string[];
  newItems?: string[];
  fixedDefaultValue?: boolean;
  disabled?: boolean;
  placeholder: string;
  className?: string;
  animation?: number;
  showBadge?: boolean;
  buttonOptions?: {
    showCloseButton?: boolean;
    showClearButton?: boolean;
  };
  customFooter?: (
    options: MultiSelectOption[],
    searchValue: string,
    selectedValues: string[],
    closePopover: () => void,
    clearSearch: () => void,
  ) => React.ReactNode;
  searchOptions?: {
    emptyState: "empty" | "add";
    addEvent?: (value: string) => void;
  };

  onValueChange: (value: string[]) => void;
  singleLine?: boolean;
}

const MultiSelectFormField = React.forwardRef<HTMLButtonElement, MultiSelectFormFieldProps>(
  (
    {
      className,
      variant,
      asChild: _asChild = false,
      options,
      newItems = [],
      defaultValue,
      fixedDefaultValue = false,
      onValueChange,
      disabled,
      placeholder,
      showBadge = true,
      animation = 0,
      singleLine,
      buttonOptions = { showClearButton: true, showCloseButton: true },
      customFooter = null,
      searchOptions = { emptyState: "empty", addEvent: () => {} },
      ...props
    },
    ref,
  ) => {
    const [selectedValues, setSelectedValues] = React.useState<string[]>(defaultValue || []);
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
    const [isAnimating, setIsAnimating] = React.useState(animation > 0);
    const [searchValue, setSearchValue] = React.useState("");

    React.useEffect(() => {
      setSelectedValues(defaultValue || []);
    }, [defaultValue]);

    const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        setIsPopoverOpen(true);
      } else if (event.key === "Backspace" && !(event.target as HTMLInputElement).value) {
        const newSelectedValues = [...selectedValues];
        newSelectedValues.pop();
        setSelectedValues(newSelectedValues);
        onValueChange(newSelectedValues);
      }
    };

    const closePopover = () => {
      setIsPopoverOpen(false);
    };

    const clearSearch = () => {
      setSearchValue("");
    };

    const toggleOption = (value: string) => {
      if (defaultValue && fixedDefaultValue === true) {
        if (defaultValue.find((item) => item === value) && !newItems.find((item) => item === value)) {
          return; // You can not uncheck a defaultValue
        }
      }

      const newSelectedValues = [...selectedValues];
      if (newSelectedValues.includes(value)) {
        newSelectedValues.splice(newSelectedValues.indexOf(value), 1);
      } else {
        newSelectedValues.push(value);
      }
      setSelectedValues(newSelectedValues);
      onValueChange(newSelectedValues);
    };

    return (
      <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            disabled={disabled}
            {...props}
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
            variant="input"
            size="input"
            className={cn("justify-between", { "h-11 justify-between": singleLine })}
          >
            {showBadge && selectedValues.length > 0 ? (
              <div className="flex w-full items-center justify-between gap-1">
                <div className={cn("flex items-center gap-1", { "w-full overflow-x-scroll": singleLine, "flex-wrap": !singleLine })}>
                  {selectedValues.map((value) => {
                    const option = options.find((o) => o.value === value);
                    const IconComponent = option?.icon;
                    return (
                      <Badge
                        key={value}
                        className={cn(isAnimating ? "animate-bounce" : "", multiSelectVariants({ variant, className }))}
                        style={{
                          animationDuration: `${animation}s`,
                        }}
                      >
                        {IconComponent && <IconComponent className="mr-2 h-4 w-4" />}
                        {option?.label}
                        <XCircle
                          className="ml-2 h-4 w-4 cursor-pointer"
                          onClick={(event) => {
                            event.stopPropagation();
                            toggleOption(value);
                          }}
                        />
                      </Badge>
                    );
                  })}
                </div>
                <div className="flex items-center justify-between gap-1">
                  <XIcon
                    className="h-4 cursor-pointer text-muted-foreground"
                    onClick={(event) => {
                      setSelectedValues([]);
                      onValueChange([]);
                      event.stopPropagation();
                    }}
                  />
                  <Separator orientation="vertical" className="flex h-full min-h-6" />
                  <ChevronDown className="h-4 cursor-pointer text-muted-foreground" />
                </div>
              </div>
            ) : (
              <div className="flex w-full items-center justify-between gap-1">
                <span className="text-sm text-muted-foreground">{placeholder}</span>
                <ChevronDown className="h-4 cursor-pointer text-muted-foreground" />
              </div>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0 drop-shadow-xs" align="start" onEscapeKeyDown={() => setIsPopoverOpen(false)}>
          <Command>
            <CommandInput placeholder="Search..." onKeyDown={handleInputKeyDown} onValueChange={setSearchValue} value={searchValue} />
            <CommandList>
              {searchOptions.emptyState === "empty" && <CommandEmpty>No results found.</CommandEmpty>}
              <CommandGroup>
                {options.map((option) => {
                  const isSelected = selectedValues.includes(option.value);
                  return (
                    <CommandItem
                      key={option.value}
                      onSelect={() => toggleOption(option.value)}
                      style={{
                        pointerEvents: "auto",
                        opacity: 1,
                      }}
                      className={cn("cursor-pointer", variant === "reverse" ? multiSelectVariants({ variant, className }) : "")}
                    >
                      <div
                        className={cn(
                          "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                          isSelected ? "bg-primary text-primary-foreground" : "opacity-50 [&_svg]:invisible",
                        )}
                      >
                        <CheckIcon className="h-4 w-4" />
                      </div>
                      {option.icon && <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />}
                      <span>{option.label}</span>
                    </CommandItem>
                  );
                })}
              </CommandGroup>
              {searchOptions.emptyState === "add" &&
                searchValue &&
                searchValue.trim().length > 0 &&
                options.length > 0 &&
                !options.find((item) => item.label.trim().toLowerCase() === searchValue.trim().toLowerCase()) && (
                  <CommandGroup
                    style={{
                      pointerEvents: "auto",
                      opacity: 1,
                    }}
                    forceMount
                  >
                    <CommandItem
                      style={{
                        pointerEvents: "auto",
                        opacity: 1,
                      }}
                      onSelect={() => {
                        if (searchOptions.addEvent) searchOptions.addEvent(searchValue);
                        clearSearch();
                      }}
                      forceMount
                    >
                      Add {searchValue}
                    </CommandItem>
                  </CommandGroup>
                )}

              <CommandSeparator />
              <CommandGroup>
                <div className="flex items-center justify-between">
                  {buttonOptions.showClearButton && selectedValues.length > 0 && (
                    <>
                      <CommandItem
                        onSelect={() => {
                          setSelectedValues([]);
                          onValueChange([]);
                        }}
                        style={{
                          pointerEvents: "auto",
                          opacity: 1,
                        }}
                        className="flex-1 cursor-pointer justify-center"
                      >
                        Clear
                      </CommandItem>
                      <Separator orientation="vertical" className="flex h-full min-h-6" />
                    </>
                  )}
                  <CommandSeparator />
                  {buttonOptions.showCloseButton && (
                    <CommandItem
                      onSelect={() => setIsPopoverOpen(false)}
                      style={{
                        pointerEvents: "auto",
                        opacity: 1,
                      }}
                      className="flex-1 cursor-pointer justify-center"
                    >
                      Close
                    </CommandItem>
                  )}
                </div>
              </CommandGroup>
            </CommandList>
          </Command>
          {customFooter && customFooter(options, searchValue, selectedValues, closePopover, clearSearch)}
        </PopoverContent>
        {animation > 0 && selectedValues.length > 0 && (
          <WandSparkles
            className={cn("my-2 h-3 w-3 cursor-pointer bg-background text-foreground", isAnimating ? "" : "text-muted-foreground")}
            onClick={() => setIsAnimating(!isAnimating)}
          />
        )}
      </Popover>
    );
  },
);

MultiSelectFormField.displayName = "MultiSelectFormField";

export default MultiSelectFormField;
