import React from "react";
import { memo } from "react";
import clsx from "clsx";

import { Icon } from "@/icon/icon";

import { TableBuilder } from "./types";

import "./table.scss";

type HeaderProps = {
  config: TableBuilder;
};

const TableHeader = memo(({ config }: HeaderProps) => {
  const classes = clsx("table__cell", {
    "table__cell--sortable": !!config.sortFunction,
    "table__cell--sorted": config.sortActive,
    "table__cell--sort-asc": config.sortActive && config.sortDirection === 1,
    "table__cell--sort-desc": config.sortActive && config.sortDirection === -1,
  });

  const getSortIcon = (config: TableBuilder) => {
    if (config.sortActive && config.sortDirection === 1) {
      return <Icon icon="ArrowDown" className="rotate-180" />;
    } else if (config.sortActive && config.sortDirection === -1) {
      return <Icon icon="ArrowDown" />;
    } else if (config.sortFunction) {
      return;
    } else {
      return null;
    }
  };

  return (
    <th className={classes} onClick={() => (config.sortFunction ? config.sortFunction() : null)}>
      <div className="table__cell-content">
        {config.headerTitle}
        {getSortIcon(config)}
      </div>
    </th>
  );
});
TableHeader.displayName = "TableHeader";

export default TableHeader;
