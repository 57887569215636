import React from "react";
import { useNavigate } from "@tanstack/react-router";

import { Combobox, ComboboxOption } from "@/combobox";
import { SidebarTrigger } from "@/ui/sidebar";

import { useGetAvailableCountriesV1MarketConfigAvailableCountriesGet } from "~/api/market-config/market-config.gen";
import steplerIcon from "~/assets/icons/stepler-logo.png";
import { parseMarketCountries } from "~/helpers/country-list";
import { useMarketStore } from "~/store/market";

const CountryCombobox = React.memo(() => {
  const navigate = useNavigate();
  const setMarket = useMarketStore((state) => state.setMarket);
  const market = useMarketStore((state) => state.market);

  const setSelectedCountry = (value: string) => {
    setMarket(value);
    navigate({
      // @ts-expect-error it works fine
      params: { market: value },
    });
  };

  const { data: countries } = useGetAvailableCountriesV1MarketConfigAvailableCountriesGet({
    query: {
      initialData: [],
    },
  });

  return (
    <Combobox
      searchName="Country"
      placeholder="Country"
      options={parseMarketCountries(countries).sort((a, b) => a.name.localeCompare(b.name)) || []}
      onChange={(value: ComboboxOption) => {
        if (value.value) {
          setSelectedCountry(value.value);
        }
      }}
      value={market}
      showFlag
    />
  );
});

const Header = React.memo(() => {
  return (
    <div className="flex max-w-[calc(100vw - var(--sidebar-width))] h-16 relative z-50 items-center justify-between bg-header px-2.5 py-4 drop-shadow-xs">
      <section className="flex gap-4 items-center">
        <SidebarTrigger />
        <img src={steplerIcon} className="h-8" alt="Stepler Logo" />
      </section>
      <section>
        <CountryCombobox />
      </section>
    </div>
  );
});

export default Header;
