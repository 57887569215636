import React from "react";
import { memo } from "react";
import PropTypes from "prop-types";

import "./input.scss";

type OwnProps = {
  wrapperClassName?: string;
};

type Props = OwnProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const Input = memo(({ name, value, type, disabled, step, ...restProps }: Props) => {
  if (!value) {
    value = "";
  }

  return (
    <input
      id={name}
      name={name}
      type={type}
      step={step}
      value={value !== undefined ? value : undefined}
      disabled={disabled}
      {...restProps}
      className="input flex w-full rounded-md bg-background px-4 py-2.5 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-hidden disabled:cursor-not-allowed disabled:opacity-50"
    />
  );
});
// @ts-expect-error memo proptypes problem
Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  disabled: PropTypes.bool,
};
Input.displayName = "Input";
