import { redirect } from "@tanstack/react-router";
import { AxiosError } from "axios";

import { TokenService } from "./tokenService";

import { refreshTokenCall, sendLoginEmail } from "~/api/auth";
import { AXIOS_INSTANCE } from "~/api/base";
import { useAuthStore } from "~/store/authStore";
import { User } from "~/types/user";

/**
 * Authentication Service - Centralizes authentication operations
 */
export class AuthService {
  // Track if a refresh is in progress to prevent multiple refreshes
  private static refreshPromise: Promise<{
    accessToken: string;
    refreshToken: string;
    user: User;
  }> | null = null;

  /**
   * Send a login email with magic link
   * @param email User's email
   * @param loginUrl URL to redirect after login
   * @returns Promise with the response data
   */
  static async sendLoginEmail(email: string, loginUrl: string) {
    return await sendLoginEmail({ email, login_url: loginUrl });
  }

  /**
   * Refresh the authentication token
   * @param refreshToken Current refresh token
   * @param userId User ID
   * @returns Promise with new tokens and user data
   * @throws Error if refresh fails
   */
  static async refreshToken(refreshToken: string, userId: string) {
    // If a refresh is already in progress, return that promise
    if (this.refreshPromise) {
      return this.refreshPromise;
    }

    // Create a new refresh promise
    this.refreshPromise = (async () => {
      try {
        const response = await refreshTokenCall({
          refreshToken,
          userId,
        });

        if (response.status !== 200) {
          throw new Error("Failed to refresh token");
        }

        const result = {
          accessToken: response.data.access_token,
          refreshToken: response.data.refresh_token,
          user: response.data.user,
        };

        // Update store with new tokens
        const state = useAuthStore.getState();
        state.setTokens({
          accessToken: result.accessToken,
          refreshToken: result.refreshToken,
        });
        state.setUser(result.user);

        // Set auth headers with new token
        this.setAuthHeaders(result.accessToken, userId);

        return result;
      } catch (error) {
        console.error("Token refresh failed:", error);
        // Clear auth state on refresh failure
        const state = useAuthStore.getState();
        state.logout();
        this.clearAuthHeaders();
        throw error;
      } finally {
        // Clear the refresh promise after completion
        setTimeout(() => {
          this.refreshPromise = null;
        }, 100); // Small delay to prevent race conditions
      }
    })();

    return this.refreshPromise;
  }

  /**
   * Attempt to refresh the token if it's expired or will expire soon
   * @param accessToken Current access token
   * @param refreshToken Current refresh token
   * @param userId User ID
   * @param expiryBufferSeconds Seconds before expiration to trigger refresh (default: 60)
   * @returns Object with refreshed tokens and user or null if refresh wasn't needed
   */
  static async refreshTokenIfNeeded(
    accessToken: string, 
    refreshToken: string, 
    userId: string,
    expiryBufferSeconds = 60
  ) {
    // Check if token is expired or will expire soon
    if (!TokenService.isTokenExpired(accessToken, expiryBufferSeconds)) {
      return null; // Token is still valid
    }

    try {
      return await this.refreshToken(refreshToken, userId);
    } catch (refreshError) {
      // Handle refresh failure - logout user
      console.error("Token refresh failed:", refreshError);
      const state = useAuthStore.getState();
      state.logout();
      redirect({ to: "/login" });
      return null;
    }
  }

  /**
   * Set authentication headers for API requests
   * @param accessToken Access token
   * @param userId User ID
   */
  static setAuthHeaders(accessToken: string, userId?: string) {
    AXIOS_INSTANCE.defaults.headers.Authorization = accessToken ? `Bearer ${accessToken}` : null;
    if (userId) {
      AXIOS_INSTANCE.defaults.headers.userid = userId;
    }
  }

  /**
   * Clear authentication headers
   */
  static clearAuthHeaders() {
    AXIOS_INSTANCE.defaults.headers.Authorization = null;
    AXIOS_INSTANCE.defaults.headers.userid = null;
  }

  /**
   * Initialize authentication state from storage
   * Validates stored tokens and refreshes if needed
   * @returns Promise that resolves when initialization is complete
   */
  static async initializeAuth() {
    const state = useAuthStore.getState();
    const { accessToken, refreshToken, user, isAuthenticated } = state;

    // If not authenticated or missing tokens/user, nothing to do
    if (!isAuthenticated || !accessToken || !refreshToken || !user?.user_id) {
      return;
    }

    try {
      // Check if token needs refresh
      const refreshResult = await this.refreshTokenIfNeeded(
        accessToken,
        refreshToken,
        user.user_id
      );

      if (refreshResult) {
        // Token was refreshed, headers are already set in refreshToken method
        console.error("Token refreshed during initialization");
      } else {
        // Token is still valid, just set the headers
        this.setAuthHeaders(accessToken, user.user_id);
      }
    } catch (error) {
      console.error("Auth initialization failed:", error);
      // Clear auth state on initialization failure
      state.logout();
    }
  }

  /**
   * Handle authentication errors
   * @param error The error object
   * @returns Promise that resolves when error handling is complete
   */
  static async handleAuthError(error: AxiosError) {
    if (error.response?.status !== 401) {
      throw error; // Not an auth error, rethrow
    }

    const state = useAuthStore.getState();
    const { refreshToken, user } = state;

    if (!refreshToken || !user?.user_id) {
      state.logout();
      redirect({ to: "/login" });
      throw error;
    }

    try {
      // This will reuse an existing refresh promise if one exists
      const refreshResult = await this.refreshToken(refreshToken, user.user_id);
      
      // Return the new access token (refreshResult is guaranteed to be non-null here)
      return refreshResult.accessToken;
    } catch (refreshError) {
      // Refresh failed, logout user
      console.error("Auth error handling failed:", refreshError);
      state.logout();
      redirect({ to: "/login" });
      throw error; // Rethrow original error
    }
  }

  /**
   * Set user data and update Sentry context
   * @param user User data
   */
  static setUser(user: User) {
    const state = useAuthStore.getState();
    state.setUser(user);
  }

  /**
   * Logout user and clear auth state
   */
  static async logout() {
    const state = useAuthStore.getState();
    this.clearAuthHeaders();
    await state.logout();
  }
}
