import React from "react";
import { FaClipboardUser, FaCodeBranch, FaEarthEurope, FaGlobe, FaUserTie } from "react-icons/fa6";

import ActionMenu from "./assets/action_menu.svg?react";
import Add from "./assets/add.svg?react";
import Analytics from "./assets/analytics.svg?react";
import ArrowDown from "./assets/arrow_down.svg?react";
import ArrowRight from "./assets/arrow_right.svg?react";
import BarChart from "./assets/bar_chart.svg?react";
import Bookmark from "./assets/bookmark.svg?react";
import Calendar from "./assets/calendar.svg?react";
import Campaigns from "./assets/campaigns.svg?react";
import CategoryBeauty from "./assets/category_beauty.svg?react";
import CategoryDiamonds from "./assets/category_diamonds.svg?react";
import CategoryDigitalServices from "./assets/category_digital_services.svg?react";
import CategoryEconomy from "./assets/category_economy.svg?react";
import CategoryExperiences from "./assets/category_experiences.svg?react";
import CategoryFamilyKids from "./assets/category_family_kids.svg?react";
import CategoryFashionAccessories from "./assets/category_fashion_accessories.svg?react";
import CategoryFoodNutrition from "./assets/category_food_nutrition.svg?react";
import CategoryHealthFitness from "./assets/category_health_fitness.svg?react";
import CategoryHomeInterior from "./assets/category_home_interior.svg?react";
import CategoryMediaStreaming from "./assets/category_media_streaming.svg?react";
import CategoryPets from "./assets/category_pets.svg?react";
import CategorySportsOutdoor from "./assets/category_sports_outdoor.svg?react";
import CategoryTechGaming from "./assets/category_tech_gaming.svg?react";
import CategoryTravel from "./assets/category_travel.svg?react";
import Checkbox from "./assets/checkbox.svg?react";
import CheckboxSolid from "./assets/checkbox_filled.svg?react";
import Check from "./assets/checkmark.svg?react";
import ChevronLeft from "./assets/chevron_left.svg?react";
import Close from "./assets/close.svg?react";
import Codes from "./assets/codes.svg?react";
import Coin from "./assets/coin.svg?react";
import Copy from "./assets/copy.svg?react";
import CustomerSupport from "./assets/customer_support.svg?react";
import DarkMode from "./assets/darkmode.svg?react";
import Dashboard from "./assets/dashboard.svg?react";
import Diamond from "./assets/diamond.svg?react";
import Download from "./assets/download.svg?react";
import DragAndDrop from "./assets/drag_and_drop.svg?react";
import Duplicate from "./assets/duplicate.svg?react";
import Edit from "./assets/edit.svg?react";
import Eye from "./assets/eye.svg?react";
import Info from "./assets/info.svg?react";
import InviteCampaign from "./assets/invite_campaigns.svg?react";
import InviteLinks from "./assets/invite_links.svg?react";
import Link from "./assets/link.svg?react";
import LogOut from "./assets/log_out.svg?react";
import MarketPlace from "./assets/marketplace.svg?react";
import Menu from "./assets/menu.svg?react";
import PartnerApps from "./assets/partner_apps.svg?react";
import PointsLinks from "./assets/points_links.svg?react";
import Search from "./assets/search.svg?react";
import Settings from "./assets/settings.svg?react";
import Share from "./assets/share.svg?react";
import StepCampaign from "./assets/step_campaigns.svg?react";
import SteplerLogo from "./assets/stepler_logo.svg?react";
import Store from "./assets/store.svg?react";
import Sunny from "./assets/sunny_outline.svg?react";
import Transactions from "./assets/transactions.svg?react";
import Trash from "./assets/trash_outline.svg?react";
import Users from "./assets/users.svg?react";
import { IconName } from "./types";

import { cn } from "~/lib/utils";

interface Props {
  icon: IconName;
  className?: string;
  justIcon?: boolean;
}

/**
 *
 * @param icon string key icon name
 * @param className string classes for styling
 */
const IconComponent = ({ icon, className, justIcon }: Props) => {
  let SvgIcon = null;

  switch (icon) {
    case "ActionMenu":
      SvgIcon = ActionMenu;
      break;
    case "Add":
      SvgIcon = Add;
      break;
    case "Analytics":
      SvgIcon = Analytics;
      break;
    case "ArrowDown":
      SvgIcon = ArrowDown;
      break;
    case "ArrowRight":
      SvgIcon = ArrowRight;
      break;
    case "BarChart":
      SvgIcon = BarChart;
      break;
    case "Bookmark":
      SvgIcon = Bookmark;
      break;
    case "Calendar":
      SvgIcon = Calendar;
      break;
    case "Campaigns":
      SvgIcon = Campaigns;
      break;
    case "Checkbox":
      SvgIcon = Checkbox;
      break;
    case "CheckboxSolid":
      SvgIcon = CheckboxSolid;
      break;
    case "Check":
      SvgIcon = Check;
      break;
    case "ChevronLeft":
      SvgIcon = ChevronLeft;
      break;
    case "Close":
      SvgIcon = Close;
      break;
    case "Codes":
      SvgIcon = Codes;
      break;
    case "Coin":
      SvgIcon = Coin;
      break;
    case "Copy":
      SvgIcon = Copy;
      break;
    case "CustomerSupport":
      SvgIcon = CustomerSupport;
      break;
    case "DarkMode":
      SvgIcon = DarkMode;
      break;
    case "Dashboard":
      SvgIcon = Dashboard;
      break;
    case "Diamond":
      SvgIcon = Diamond;
      break;
    case "Download":
      SvgIcon = Download;
      break;
    case "DragAndDrop":
      SvgIcon = DragAndDrop;
      break;
    case "Duplicate":
      SvgIcon = Duplicate;
      break;
    case "Edit":
      SvgIcon = Edit;
      break;
    case "Eye":
      SvgIcon = Eye;
      break;
    case "Info":
      SvgIcon = Info;
      break;
    case "InviteCampaign":
      SvgIcon = InviteCampaign;
      break;
    case "InviteLinks":
      SvgIcon = InviteLinks;
      break;
    case "Link":
      SvgIcon = Link;
      break;
    case "LogOut":
      SvgIcon = LogOut;
      break;
    case "MarketPlace":
      SvgIcon = MarketPlace;
      break;
    case "Menu":
      SvgIcon = Menu;
      break;
    case "PartnerApps":
      SvgIcon = PartnerApps;
      break;
    case "PointsLinks":
      SvgIcon = PointsLinks;
      break;
    case "Search":
      SvgIcon = Search;
      break;
    case "Settings":
      SvgIcon = Settings;
      break;
    case "Share":
      SvgIcon = Share;
      break;
    case "StepCampaign":
      SvgIcon = StepCampaign;
      break;
    case "Store":
      SvgIcon = Store;
      break;
    case "SteplerLogo":
      SvgIcon = SteplerLogo;
      break;
    case "Sunny":
      SvgIcon = Sunny;
      break;
    case "Transactions":
      SvgIcon = Transactions;
      break;
    case "Trash":
      SvgIcon = Trash;
      break;
    case "Users":
      SvgIcon = Users;
      break;
    case "CategoryBeauty":
      SvgIcon = CategoryBeauty;
      break;
    case "CategoryDiamonds":
      SvgIcon = CategoryDiamonds;
      break;
    case "CategoryDigitalServices":
      SvgIcon = CategoryDigitalServices;
      break;
    case "CategoryEconomy":
      SvgIcon = CategoryEconomy;
      break;
    case "CategoryExperiences":
      SvgIcon = CategoryExperiences;
      break;
    case "CategoryFamilyKids":
      SvgIcon = CategoryFamilyKids;
      break;
    case "CategoryFashionAccessories":
      SvgIcon = CategoryFashionAccessories;
      break;
    case "CategoryFoodNutrition":
      SvgIcon = CategoryFoodNutrition;
      break;
    case "CategoryHealthFitness":
      SvgIcon = CategoryHealthFitness;
      break;
    case "CategoryHomeInterior":
      SvgIcon = CategoryHomeInterior;
      break;
    case "CategoryMediaStreaming":
      SvgIcon = CategoryMediaStreaming;
      break;
    case "CategoryPets":
      SvgIcon = CategoryPets;
      break;
    case "CategorySportsOutdoor":
      SvgIcon = CategorySportsOutdoor;
      break;
    case "CategoryTechGaming":
      SvgIcon = CategoryTechGaming;
      break;
    case "CategoryTravel":
      SvgIcon = CategoryTravel;
      break;
    case "FaCodeBranch":
      SvgIcon = FaCodeBranch;
      break;
    case "FaClipboardUser":
      SvgIcon = FaClipboardUser;
      break;
    case "FaGlobe":
      SvgIcon = FaGlobe;
      break;
    case "FaEarthEurope":
      SvgIcon = FaEarthEurope;
      break;
    case "FaUserTie":
      SvgIcon = FaUserTie;
      break;
    default:
      break;
  }

  if (!SvgIcon) return null;

  if (justIcon) {
    return <SvgIcon className={cn("w-4 h-4 stroke-", className)} />;
  }

  return (
    <div aria-label={icon} role="img">
      <SvgIcon className={cn("w-4 h-4 stroke-", className)} />
    </div>
  );
};

export const Icon = React.memo(IconComponent);
