import React from 'react'
import { useEffect, useState } from 'react'
import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { subMinutes } from 'date-fns'
import { toast } from 'sonner'

import { Icon } from '@/icon/icon'

import { getGetMarketplaceDataV1MarketplaceGetSuspenseQueryOptions } from '~/api/marketplace/marketplace.gen'
import { OfferRelation, OfferStatus, OfferType } from '~/api/model'
import { getGetOfferByIdV1OffersOfferIdGetSuspenseQueryOptions } from '~/api/offers/offers.gen'
import { convertToUsableDate } from '~/helpers/date-formatting'
import OfferForm from '~/pages/Offers/offerPart/OfferForm'
import SplashPage from '~/pages/Splash/Splash'
import { getPlatformString, OfferWithCategoriesAndTags } from '~/providers/marketplace'

export const Route = createFileRoute(
  '/_auth/$market/marketplace/rewards/$rewardId/',
)({
  loader: ({ context: { queryClient }, params: { rewardId, market } }) => {
    queryClient.ensureQueryData(
      getGetOfferByIdV1OffersOfferIdGetSuspenseQueryOptions(rewardId),
    )
    queryClient.ensureQueryData(
      getGetMarketplaceDataV1MarketplaceGetSuspenseQueryOptions({ market }),
    )
  },
  component: () => <EditOfferPage />,
})

function EditOfferPage() {
  const rewardId = Route.useParams().rewardId
  const market = Route.useParams().market

  const {
    data: offer,
    refetch,
    error,
    status,
  } = useSuspenseQuery(
    getGetOfferByIdV1OffersOfferIdGetSuspenseQueryOptions(rewardId),
  )
  const { data } = useSuspenseQuery(
    getGetMarketplaceDataV1MarketplaceGetSuspenseQueryOptions({ market }),
  )

  const [reward, setReward] = useState<OfferWithCategoriesAndTags>()

  useEffect(() => {
    if (status !== 'success') return
    if (offer && data.tags.length > 0 && data.categories.length > 0) {
      const offerCategories = data.categories.filter((category) =>
        category.rewardOrder.includes(offer._id || ''),
      )
      const offerTags = data.tags.filter((tag) =>
        tag.rewardOrder?.includes(offer._id || ''),
      )
      setReward({
        ...offer,
        id: offer._id?.toString() || undefined,
        fullTitle: `${offer.titleV2} - ${offer.subTitle}`,
        categories: offerCategories,
        categoryIds: offerCategories.map((category) => category.category),
        categoryIdsString: offerCategories
          .map((category) => category.category)
          .join(','),
        tags: offerTags,
        tagIds: offerTags.map((tag) => tag._id?.toString() as string),
        tagIdStrings: offerTags
          .map((tag) => tag._id?.toString() as string)
          .join(','),
        tagNames: offerTags
          .map((tag) => tag.name?.toString() as string)
          .join(','),
        isBonus: false,
        platformString: getPlatformString(offer.platform),
      })
    }
  }, [status, offer, data.tags, data.categories])

  if (!reward) {
    return <SplashPage />
  }

  if (error) {
    return <div>Could not load the reward</div>
  }

  return (
    <section className="flex flex-col gap-10 px-8 py-10 bg-background">
      <section>
        <div className="flex gap-4 header-titles_wrapper">
          <h2 className="text-foreground">Edit Reward</h2>
          <span className="flex gap-2 text-foreground">
            Reward id: {offer._id}
            <span
              className="flex items-center cursor-pointer text-foreground"
              onClick={() => {
                navigator.clipboard.writeText(offer._id as string)
                toast('Reward id successfully copied to clipboard!')
              }}
            >
              <Icon icon="Copy" className="w-4 h-4" />
            </span>
          </span>
        </div>
        <div className="button-group" />
      </section>
      <OfferForm
        refetch={refetch}
        offerModel={reward}
        offer={{
          _id: rewardId,
          type: reward.type || OfferType.Regular,
          price: reward.price,
          diamonds: reward.diamonds || undefined,
          subTitle: reward.subTitle || '',
          companyDescription: reward.companyDescription || '',
          shortDescription: reward.shortDescription,
          url: reward.url || '',
          country: reward.country,
          categories: reward.categoryIds,
          tags: reward.tagIds,
          steplerOnly: reward.steplerOnly || false,
          containsSubscription: reward.containsSubscription || false,
          titleV2: reward.titleV2,
          longDescriptionV2: reward.longDescriptionV2,
          amount: reward.amount,
          order: reward.order || 1,
          purchaseCount:
            reward.purchaseCount === null ? undefined : reward.purchaseCount,
          publishDate:
            convertToUsableDate(reward.publishDate) ||
            subMinutes(new Date(), 5),
          unPublishDate:
            convertToUsableDate(reward.unPublishDate) ||
            subMinutes(new Date(), 3),
          qty: reward.terms?.qty || '',
          shipping: reward.terms?.shipping || '',
          valid: reward.terms?.valid || '',
          other: reward.terms?.other || '',
          value: reward.terms?.value || '',
          ios: reward.platform.ios || false,
          android: reward.platform.android || false,
          status: reward.status || OfferStatus.Unpublished,
          hideInList: reward.hideInList || false,
          relation: reward.relation || OfferRelation.Partner_offer,
          logoUrl: reward.logoUrl || '',
          thumbnailUrl: reward.thumbnailUrl,
          bannerUrl1: reward.bannerUrls[0] || null,
          bannerUrl2: reward.bannerUrls[1] || null,
          bannerUrl3: reward.bannerUrls[2] || null,
        }}
      />
    </section>
  )
}
